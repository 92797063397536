import * as bip39 from 'bip39';
import * as nacl from 'tweetnacl';
import * as ed from 'ed25519-hd-key';
import {KeyPair} from '../key-pair';

/**
 * Key pair generator
 */
export class KeyPairGenerator {

  /**
   * Generates key pair by given bip39 mnemonic
   *
   * @param {string} mnemonic
   * @returns {Promise<KeyPair>}
   */
  public async byMnemonic(mnemonic: string): Promise<KeyPair> {
    const seed = (await bip39.mnemonicToSeed(mnemonic)).toString('hex');
    const key = ed.derivePath('m/44\'/148\'/0\'', seed).key;
    const keyPair = nacl.sign.keyPair.fromSeed(key);
    return new KeyPair(keyPair.secretKey, keyPair.publicKey, mnemonic);
  }

  /**
   * Generates key pair by random
   *
   * @returns {Promise<KeyPair>}
   */
  public async byRandom(): Promise<KeyPair> {
    const mnemonic = bip39.generateMnemonic(256);
    return await this.byMnemonic(mnemonic);
  }
}
