import {Injectable} from '@angular/core';


/**
 * Registry service
 */
@Injectable()
export class RegistryService {
  private storage: any = {};

  /**
   * Sets registry key to given value
   *
   * @param key
   * @param value
   */
  public set(key, value): void {
    this.storage[key] = value;
  }

  /**
   * Returns value for given registry key
   *
   * @param key
   */
  public get(key): any {
    return this.storage[key] || null;
  }
}
