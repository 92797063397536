export const permission = {
  UMS_TENANT_READ: 'UMS_TENANT_READ',
  UMS_USER_CREATE: 'UMS_USER_CREATE',
  UMS_USER_READ: 'UMS_USER_READ',
  UMS_USER_UPDATE: 'UMS_USER_UPDATE',
  UMS_USER_DELETE: 'UMS_USER_DELETE',
  UMS_USER_ROLE_READ: 'UMS_USER_ROLE_READ',
  UMS_USER_ROLE_ASSIGN: 'UMS_USER_ROLE_ASSIGN',
  UMS_USER_ROLE_UNASSIGN: 'UMS_USER_ROLE_UNASSIGN',
  UMS_USER_PERMISSION_READ: 'UMS_USER_PERMISSION_READ',
  UMS_ROLE_READ: 'UMS_ROLE_READ'
};
