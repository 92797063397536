import {Component, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'app-global-style',
  template: '',
  styleUrls: ['./global-style.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalStyleComponent {

}
