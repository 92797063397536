import {NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutService} from '@ngmedax/layout';
import {Router} from '@angular/router';

import {AuthorizationGuard} from './guard/authorization.guard';
import {PermissionService} from './permission.service';
import {PermissionConfigService} from './permission.config.service';
import {LoginService} from '@ngmedax/login';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    PermissionService,
    PermissionConfigService,
    AuthorizationGuard
  ]
})
export class PermissionModule {
  private originalNavigation: any[];

  constructor(
    private authorizationGuard: AuthorizationGuard,
    private layoutService: LayoutService,
    @Optional() private loginService: LoginService,
    private permissionService: PermissionService,
    private router: Router) {

    if (!loginService) {
      console.warn('Not found: LoginService, skipping permission checks!');
      return;
    }

    this.layoutService.registerGuard(authorizationGuard, 200);

    this.layoutService.subscribeToUserChanged(() => {
      const routes: any = [];

      for (const routeConfig of this.router.config) {
        routes[routeConfig.path] = routeConfig;
      }

      if (!this.originalNavigation) {
        this.originalNavigation = this.clone(layoutService.getMenu());
      }

      const navigation = this.clone(this.originalNavigation);
      this.filterNavigation(routes, navigation);
      this.layoutService.setMenu(navigation);
    });
  }

  /**
   * Filters out all navigation entries that are not allowed.
   * Also removes main entries when all children are disallowed.
   *
   * @param {any[]} routes
   * @param {any[]} navigation
   */
  private filterNavigation(routes: any[], navigation: any[]): void {
    // recursive method to tag menu entries with allowed flag
    const tag = (routesList: any[], nav: any[]) => {
      for (const entry of nav) {
        if (entry.children) {
          tag(routesList, entry.children);
        } else {
          if (!entry.path) {
            continue;
          }

          const path = entry.path.replace(/^\//, '');
          const route = routesList[path];

          if (!route) {
            continue;
          }

          entry.allowed = this.permissionService.isAllowedByRouteData(route.data || {}).allowed;
        }
      }
    };

    // recursive method to remove all menu entries tagged with allowed = false
    const filter = (nav: any[]) => {
      for (let i = nav.length; i--;) {
        if (nav[i].children) {
          filter(nav[i].children);

          if (!nav[i].children.length) {
            nav.splice(i, 1);
          }
        } else {
          if (typeof nav[i].allowed !== 'undefined' && !nav[i].allowed) {
            nav.splice(i, 1);
          }
        }
      }
    };

    // add allowed flag
    tag(routes, navigation);

    // remove disallowed
    filter(navigation);
  }

  /**
   * Clones given input var
   *
   * @param input
   * @returns {any}
   */
  private clone(input: any): any {
    return JSON.parse(JSON.stringify(input));
  }
}
