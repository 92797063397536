import {Injectable, Optional} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {ErrorService} from '@ngmedax/error';
import {RegistryService} from '@ngmedax/registry';
import {ConfigService} from '@ngmedax/config';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_DEFAULT_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {ApiService} from '../service/api.service';
import {LicenseResponse} from '../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface LicenseGuard extends Translatable {}

@Injectable()
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class LicenseGuard implements CanActivate {
  /**
   * License response
   * @type {LicenseResponse}
   */
  private licenseResponse: LicenseResponse = null;

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private registryService: RegistryService,
    private configService: ConfigService,
    private errorService: ErrorService,
    private apiService: ApiService,
  ) {
  }

  /**
   * Checks for valid license. Redirects to error page when no valid license found.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      !this.licenseResponse && (this.licenseResponse = await this.apiService.getLicense());
      this.licenseResponse && (this.registryService.set('license', this.licenseResponse.license));
      this.applyConfig();

      if (this.isExpiredLicense()) {
        this.showExpiredLicenseMessage();
        return false;
      }

      const domains = (this.licenseResponse && this.licenseResponse.license && this.licenseResponse.license.constraint) ?
        this.licenseResponse.license.constraint.domains : null;

      if (!domains || !Array.isArray(domains) || !domains.length) {
        return true;
      }

      if (domains.indexOf(location.host) === -1) {
        console.error(`license not valid for domain: ${location.host}, allowed: ${domains.join(', ')}`);
        this.showInvalidLicenseMessage();
        return false;
      }

    } catch (error) {
      console.error(error);
      this.showInvalidLicenseMessage();
      return false;
    }

    return true;
  }

  /**
   * Redirects to "invalid license" error page
   */
  private showInvalidLicenseMessage() {
    const uri = 'license';

    const title = this._(KEYS.DEFAULT.LICENSE_ERROR);
    const message = this._(KEYS.DEFAULT.ERROR_LICENSE_INVALID);

    this.errorService
      .addErrorMessage(uri, title, message)
      .navigateToError(uri);
  }

  /**
   * Redirects to "invalid license" error page
   */
  private showExpiredLicenseMessage() {
    const uri = 'license-expired';

    const title = this._(KEYS.DEFAULT.LICENSE_ERROR);
    const message = this._(KEYS.DEFAULT.ERROR_LICENSE_EXPIRED);

    this.errorService
      .addErrorMessage(uri, title, message, this.licenseResponse.license)
      .navigateToError(uri);
  }

  /**
   * Returns true if license is expired
   *
   * @returns {boolean}
   */
  private isExpiredLicense(): boolean {
    if (!this.licenseResponse) {
      return false;
    }

    const license = this.licenseResponse.license;

    if (license.validUntil && Date.now() > Date.parse(license.validUntil)) {
      return true;
    }
  }

  /**
   * Applies config from license
   */
  private applyConfig() {
    if (!this.licenseResponse) {
      return false;
    }

    const cfg = this.configService;
    const license = this.licenseResponse.license;

    const modF = {
      'appLinkSend': (val) => cfg.modify((cfg) => cfg.feature.patient.app.link.send = val),
      'appLinkCopy': (val) => cfg.modify((cfg) => cfg.feature.patient.app.link.copy = val),
      'appLinkOpen': (val) => cfg.modify((cfg) => cfg.feature.patient.app.link.open = val),
      'appAllowMail': (val) => cfg.modify((cfg) => cfg.feature.patient.app.mail = val),
      'anonPatient': (val) => cfg.modify((cfg) => cfg.feature.patient.anonymous = val),
      'appPatientUpload': (val) => cfg.modify((cfg) => cfg.feature.editor.patient.upload = val),
      'pdfForms': (val) => cfg.modify((cfg) => cfg.feature.pdf.forms = val),
      'signoSign': (val) => cfg.modify((cfg) => cfg.feature.signoSign = val),
      'extendedWysiwyg': (val) => cfg.modify((cfg) => cfg.feature.editor.extendedWysiwyg = val)
    };

    const modC = {
      'numQuest': (val) => cfg.modify((cfg) => cfg.constraint.numQuestionnaires = val),
      'numDevices' : (val) => cfg.modify((cfg) => cfg.constraint.numDevices = val),
    };

    license.feature && Object.keys(license.feature).forEach(key => modF[key] && modF[key](license.feature[key]));
    license.constraint && Object.keys(license.constraint).forEach(key => modC[key] && modC[key](license.constraint[key]));
    license.feature && typeof license.feature.signoSign === 'undefined' && (license.feature.signoSign = true);
  }
}
