export function Translatable(opts: {scope: string | string[], keys?: any}) {
  return function (constructorFn: Function) {
    constructorFn.prototype._ = function (text: string, variables?: any, scope?: string | string[]) {
      return this.translationService ? this.translationService.translate({text, variables, scope: scope || opts.scope}) : text;
    };

    constructorFn.prototype.getDateFormat = function (format: string, locale?: string) {
      return this.translationService ? this.translationService.getDateFormat(format, locale) : format;
    };

    constructorFn.prototype.KEYS = opts.keys || {};
  }
}

export interface Translatable {
  _(text: string, variables?: any): string
  getDateFormat(format: string, locale?: string): string;
  KEYS: any;
}
