import {Component, OnInit} from '@angular/core';
import {UserCrudComponent} from '../user-crud/user-crud.component';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {Translatable} from '@ngmedax/translation';


/**
 * Component to change user data and user assignments (filter groups, roles, etc) of currently logged in user
 */
@Component({
  selector: 'app-user-crud',
  templateUrl: '../user-crud/user-crud.component.html',
  styleUrls: ['../user-crud/user-crud.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class UserChangeComponent extends UserCrudComponent implements OnInit {
  /**
   * Loads currently active user and calls parent method
   */
  public ngOnInit() {
    // user is required to type in current password
    this.requireCurrentPassword = true;

    const loadActiveUser = async () => {
      try {
        if (!this.loginService || !this.loginService.getUser || !this.loginService.getUser().getUserId()) {
          alert(this._(KEYS.CRUD.USER_SERVICE_NOT_AVAILABLE));
          return;
        }

        const userId = this.loginService.getUser().getUserId();
        const user = await this.userService.loadUser(userId);

        if (!user) {
          alert(this._(KEYS.CRUD.UNABLE_TO_FIND_USER_BY_ID, {id: userId}));
          return;
        }

        this.user = user;
        this.renderUser(user);

      } catch (error) {
        console.error(error);
        alert(this._(KEYS.CRUD.ERROR_LOADING_USER));
      }
    };

    loadActiveUser().then().catch();
    super.ngOnInit();
  }
}
