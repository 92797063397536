import {ActiveToast, IndividualConfig, ToastrService} from 'ngx-toastr';
import {ApplicationRef, Inject, Injectable, Optional} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslationService} from '@ngmedax/translation';

@Injectable()
export class ToastService {
  /**
   * Injects dependencies and overrides window[alert,confirm] when configured.
   */
  public constructor(
    private sanitizer: DomSanitizer,
    private adapter: ToastrService,
    private appRef: ApplicationRef,
    @Inject('TOAST_CONFIG') private config,
    @Optional() private translationService: TranslationService,
  ) {
    const origAlert = window.alert;
    const origConfirm = window.confirm;

    if (typeof config !== 'object' || !config) {
      return;
    }

    if (config.alertHack) {
      window.alert = (message) => this.alertOverride(message);

      window.confirm = (message) => {
        window.alert = origAlert;
        const res = origConfirm(message);
        window.alert = (message) => this.alertOverride(message);
        return res;
      }
    }

    if (config.confirmHack) {
      (<any>window).confirm = (message) => this.confirm(message);
    }
  }

  /**
   * Show confirm toast
   *
   * @param {string} message
   * @returns {boolean}
   */
  async confirm(message: string): Promise<boolean> {
    // disabled for now;
    return new Promise<boolean>((resolve, reject) => {
      const id = 'confirm-' + this.getRandomString();
      const toastClass = `ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-info confirm ${id}`;
      const ref = this.adapter.info(message, null, {timeOut: 1000000, tapToDismiss: false, toastClass});
      const toastSelector = `.${id}`;

      const iRef = setInterval(() => {
        const el = document.querySelectorAll(toastSelector)[0];

        if (!el) {
          return;
        }

        clearInterval(iRef);

        const scope = 'default';
        const txtOk = this.translationService ? this.translationService.translate({text: 'Ok', scope}) : 'Ok';
        const txtCancel = this.translationService ? this.translationService.translate({text: 'Abbrechen', scope}) : 'Abbrechen';

        el.innerHTML =
          `<div class="confirm">
            ${message}<br>
            <div class="mt-1 confirm-decision">
              <button class="btn btn-sm btn-primary mr-1 action-cancel">${txtCancel}</button>
              <button class="btn btn-sm btn-primary action-ok">${txtOk}</button>
            </div>
          </div>`;

        const body = document.getElementsByTagName('body')[0];
        const modalOverlay = document.createElement('block');
        modalOverlay.className = 'modal-overlay';
        body.appendChild(modalOverlay);

        const cancelBtn: HTMLElement = <any>document.querySelectorAll(`${toastSelector} .action-cancel`)[0];
        const okBtn: HTMLElement = <any>document.querySelectorAll(`${toastSelector} .action-ok`)[0];

        if (!cancelBtn || !okBtn) {
          reject(new Error('Buttons not found in dom'));
          return;
        }

        cancelBtn.addEventListener('click', () => {
          this.remove(ref.toastId);
          modalOverlay.remove();
          resolve(false);
          this.appRef.tick();
        });

        okBtn.addEventListener('click', () => {
          this.remove(ref.toastId);
          modalOverlay.remove();
          resolve(true);
          this.appRef.tick();
        });
      }, 100);
    });
  }

  /** show toast */
  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
    const ref = this.adapter.show(message, title, override, type);
    this.autoDestroyToast(ref, override);
    return ref;
  }

  /** show successful toast */
  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const ref = this.adapter.success(message, title, override);
    this.autoDestroyToast(ref, override);
    return ref;
  }

  /** show error toast */
  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const ref = this.adapter.error(message, title, override);
    this.autoDestroyToast(ref, override);
    return ref;
  }

  /** show info toast */
  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const ref = this.adapter.info(message, title, override);
    this.autoDestroyToast(ref, override);
    return ref;
  }

  /** show warning toast */
  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    const ref = this.adapter.warning(message, title, override);
    this.autoDestroyToast(ref, override);
    return ref;
  }

  /**
   * Remove all or a single toast by id
   */
  clear(toastId?: number): void {
    this.adapter.clear(toastId);
  }

  /**
   * Remove and destroy a single toast by id
   */
  remove(toastId: number): boolean {
    return this.adapter.remove(toastId);
  }

  /**
   * Auto destroys toast
   * @param ref
   * @param override
   */
  private autoDestroyToast(ref: ActiveToast<any>, override?: Partial<IndividualConfig>) {
    ref.onTap.subscribe(() => setTimeout(() => this.remove(ref.toastId), 700));

    const timeout = override ? override.timeOut : (this.adapter.toastrConfig.timeOut || 5000);
    setTimeout(() => this.remove(ref.toastId), timeout);
  }

  /**
   * Replacement for window.alert. Tries to auto detect message type by message content in languages de,en,fr,cn.
   * - Uses "success" message, when message contains word "success"
   * - Uses "error" message, when message contains word "error"
   *
   * @param message: string
   */
  private alertOverride(message: string) {
    message = `${message}`;

    const map = {
      success: /(erfolg)|(success)|(succès)|(成功)/i,
      warning: /(achtung)|(warnung)|(warning)|(attention)|(注意)/i,
      error: /(fehler)|(error)|(erreur)|(错误)/i,
    };

    const method = Object.keys(map).reduce((all, method) => message.match(map[method]) ? method : (all || 'info'), '');
    this[method](message, null, method === 'error' ? {timeOut: 7000} : null);
  }

  private getRandomString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
}
