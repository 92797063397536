// @ts-nocheck
import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation
} from '@angular/core';


declare const $: any;

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.css'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Component to wrap usage of select2 jquery component
 */
export class Select2Component implements AfterViewInit {
  /**
   * Element ref to main component element
   */
  @ViewChild('cmpEl') cmpEl: ElementRef;

  /**
   * Element ref to select2 element
   */
  @ViewChild('select2') select2El: ElementRef;

  /**
   * Select with multiple options?
   */
  @Input() multiple: boolean;

  /**
   * Label to show
   */
  @Input() label = '';

  /**
   * Should we hide the label?
   */
  @Input() hideLabel = false;

  /**
   * Should we hide the button?
   */
  @Input() hideButton = false;

  /**
   * Font awesome icon for button
   */
  @Input() buttonFaIcon = 'fa-plus';

  /**
   * String with css classes for select html element
   */
  @Input() selectCssClasses = '';

  /**
   * String with css classes for component html element
   */
  @Input() cmpCssClasses = '';

  /**
   * String with css classes for button html element
   */
  @Input() btnCssClasses = 'btn-success';

  /**
   * Should we disable submit on enter key?
   */
  @Input() disableSubmitOnEnter = false;

  /**
   * Default select2 options
   */
  @Input() options = {width: '100%', placeholder: '...'};

  /**
   * Select2 events to add
   */
  @Input() events: {[eventName: string]: Function} = {};

  /**
   * Event emitter which transmits the component element ref
   */
  @Output() cmpElement: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  /**
   * Event emitter which transmits the select element ref
   */
  @Output() selectElement: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  /**
   * Event emitter for when button was clicked
   */
  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Event emitter for when selection was submitted (via button press or enter key)
   */
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Currently selected values. You can use this for two-way data binding
   */
  @Input() set selected(value: any) {
    setTimeout(() => {
      this._selected = value;
      $(this.select2El.nativeElement).val(this._selected).trigger('change');
    }, 500);
  }

  /**
   * Change event emitter for two way data binding
   */
  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();

  private _selected: any;

  /**
   * Initializes component
   */
  public ngAfterViewInit() {
    this.cmpElement.emit(this.cmpEl);
    this.selectElement.emit(this.select2El);
    this.renderSelect2();
  }

  /**
   * Emits click and submit events
   */
  public onSubmit() {
    this.click.emit();
    const isArray = Array.isArray(this._selected);

    if (isArray && this._selected.length || !isArray && this._selected) {
      this.submit.emit(this._selected);
    }
  }

  /**
   * Converts normal select html element into dynamic select2 element
   */
  private renderSelect2() {
    const cmp = this;
    const select = $(this.select2El.nativeElement);

    // convert selects
    select.select2(this.options);

    // focuses search input field when select2 was opened
    select.on('select2:open', function() {
      const dropDownSearchEl: HTMLElement = document.querySelector('.select2-dropdown input.select2-search__field');
      
      if (dropDownSearchEl) {
        dropDownSearchEl.focus();
        return;
      }

      const searchEl: HTMLElement = select[0].querySelector('.select2-container input.select2-search__field');
      searchEl && searchEl.focus();
    })

    // sets selected property
    select.on('select2:select', function() {
      // @ts-ignore
      cmp._selected = $(this).val();
      cmp.selectedChange.emit(cmp._selected);
    });

    // sets selected property
    select.on('select2:unselect', function() {
      cmp._selected = $(this).val();
      cmp.selectedChange.emit(cmp._selected);
    });

    // stupid hack to remove unwanted tooltips
    $('.select2-selection__rendered').hover(function () {
      $(this).removeAttr('title');
    });

    // stupid hack to make select2 el auto scale the width
    setTimeout(() => $('.select2-search__field').css('width', 'auto'), 800);

    // register custom select2 events
    if (typeof this.events === 'object') {
      for (const eventName of Object.keys(this.events)) {
        const fn = this.events[eventName];
        (typeof fn === 'function') && select.on(eventName, fn);
      }
    }

    if (this.disableSubmitOnEnter) {
      return;
    }

    /**
     * Hack to trigger submit, when selection was made via enter key.
     */
    select.on('select2:open', () => {
      const dropdown = $('.select2-dropdown');

      if (!dropdown.length) {
        return;
      }

      const searchField = $('.select2-dropdown .select2-search__field');
      searchField.unbind('keydown.submitOnEnterCustom');
      // NOTE: returning false will stop propagation for all registered key down event handler!
      searchField.bind('keydown.submitOnEnterCustom', (event) => (event.which === 13) && this.onSubmit() || true);
    });
  }
}
