import {License} from '../../../../types';
import {Converter} from '../converter';
import {KeyPair} from '../key-pair';
import {Signer} from '../signer';
import {Nonce} from '../nonce';


/**
 * License generator
 */
export class LicenseGenerator {

  /**
   * Initializes new license generator
   *
   * @param {Signer} signer
   * @param {Converter} converter
   * @param {Nonce} nonce
   */
  public constructor(
    private signer: Signer,
    private converter: Converter,
    private nonce: Nonce) {
  }

  /**
   * Generates license key by given license data
   *
   * @param {License} licenseData
   * @param keyPair
   */
  public generate(licenseData: License, keyPair: KeyPair) {
    const privateKey = keyPair.getPrivateKey();

    licenseData = this.nonce.add(licenseData);
    const signedLicense = this.signer.sign(licenseData, privateKey);
    return this.converter.toBase64(signedLicense);
  }
}
