import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {QuestionComponent} from './questionnaire-editor/components/question/question.component';
import {PageBreakComponent} from './questionnaire-editor/components/page-break/page-break.component';
import {QuestionTypeChoiceComponent} from './questionnaire-editor/components/question/question-types/question-type-choice.component';
import {QuestionTypeTextComponent} from './questionnaire-editor/components/question/question-types/question-type-text.component';
import {QuestionTypeNumericComponent} from './questionnaire-editor/components/question/question-types/question-type-numeric.component';
import {QuestionTypeDateComponent} from './questionnaire-editor/components/question/question-types/question-type-date.component';
import {QuestionTypeMatrixComponent} from './questionnaire-editor/components/question/question-types/question-type-matrix.component';
import {QuestionTypeCanvasComponent} from './questionnaire-editor/components/question/question-types/question-type-canvas.component';
import {QuestionTypeSignatureComponent} from './questionnaire-editor/components/question/question-types/question-type-signature.component';

export const CONTAINER_FORMAT_TO_COMPONENT_MAPPINGS: any = {
  [Questionnaire.Container.Format.TEXT]: {
    main: QuestionComponent,
    sub: QuestionTypeTextComponent,
    title: 'Freitext',
    icon: 'fa-pencil'
  },
  [Questionnaire.Container.Format.NUMERIC]: {
    main: QuestionComponent,
    sub: QuestionTypeNumericComponent,
    title: 'Zahl',
    icon: 'fa-sort-numeric-asc'
  },
  [Questionnaire.Container.Format.DATE]: {
    main: QuestionComponent,
    sub: QuestionTypeDateComponent,
    title: 'Datum',
    icon: 'fa-calendar-o'
  },
  [Questionnaire.Container.Format.SINGLE_CHOICE]: {
    main: QuestionComponent,
    sub: QuestionTypeChoiceComponent,
    title: 'Eine Antwort',
    icon: 'fa-dot-circle-o'
  },
  [Questionnaire.Container.Format.MULTIPLE_CHOICE]: {
    main: QuestionComponent,
    sub: QuestionTypeChoiceComponent,
    title: 'Mehrere Antworten',
    icon: 'fa-check-square-o'
  },
  [Questionnaire.Container.Format.MATRIX]: {
    main: QuestionComponent,
    sub: QuestionTypeMatrixComponent,
    title: 'Fragen-Matrix',
    icon: 'fa-th'
  },
  [Questionnaire.Container.Format.PAGE_BREAK]: {
    main: PageBreakComponent,
    sub: null,
    title: 'Seitenumbruch',
    icon: 'fa-mail-forward'
  },
  [Questionnaire.Container.Format.INFO]: {
    main: QuestionComponent,
    sub: QuestionTypeTextComponent,
    title: 'Infotext',
    icon: 'fa-info-circle'
  },
  [Questionnaire.Container.Format.CANVAS]: {
    main: QuestionComponent,
    sub: QuestionTypeCanvasComponent,
    title: 'Zeichnung',
    icon: 'fa-paint-brush'
  },
  [Questionnaire.Container.Format.PICTURE]: {
    main: QuestionComponent,
    sub: QuestionTypeCanvasComponent,
    title: 'Bild aufnehmen',
    icon: 'fa-paint-brush'
  },
  [Questionnaire.Container.Format.SIGNATURE]: {
    main: QuestionComponent,
    sub: QuestionTypeSignatureComponent,
    title: 'Unterschrift',
    icon: 'fa-pencil-square'
  },


  [Questionnaire.Container.Format.YES_NO]: {
    main: QuestionComponent,
    sub: QuestionTypeChoiceComponent,
    title: 'Ja/Nein',
    icon: 'fa-dot-circle-o'
  },
  [Questionnaire.Container.Format.YES_NO_MISC]: {
    main: QuestionComponent,
    sub: QuestionTypeChoiceComponent,
    title: 'Ja/Nein/Sonstiges',
    icon: 'fa-dot-circle-o'
  },
  [Questionnaire.Container.Format.SCALE]: {
    main: QuestionComponent,
    sub: QuestionTypeMatrixComponent,
    title: 'Skala',
    icon: 'fa-th'
  },
};

export const TEMPLATE_TO_ICON_MAPPING = {
  NUMERIC: 'fa-sort-numeric-asc',
  DATE: 'fa-calendar-o',
  INFO: 'fa-info-circle',
  TEXT: 'fa-pencil',
  CANVAS: 'fa-paint-brush',
  PICTURE: 'fa-camera',
  SIGNATURE: 'fa-pencil-square',
  SINGLE_CHOICE: 'fa-dot-circle-o',
  MULTIPLE_CHOICE: 'fa-check-square-o',
  MATRIX: 'fa-th',
  SCALE: 'fa-sliders',
  YES_NO: 'fa-dot-circle-o',
  YES_NO_MISC: 'fa-dot-circle-o',
  PAGE_BREAK: 'fa-file-text-o',
  COPY: 'fa-copy'
};

export const FORMAT_TO_TEMPLATE_MAPPING = {
  'pageBreak': 'PAGE_BREAK',
  'singleChoice': 'SINGLE_CHOICE',
  'multipleChoice': 'MULTIPLE_CHOICE',
  'matrix': 'MATRIX',
  'numeric': 'NUMERIC',
  'text': 'TEXT',
  'date': 'DATE',
  'info': 'INFO',
  'canvas': 'CANVAS',
  'picture': 'PICTURE',
  'signature': 'SIGNATURE'
};
