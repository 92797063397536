import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class ErrorService {
  private static messages: any = {};

  /**
   * Injects dependencies
   */
  constructor(private router: Router) {
  }

  /**
   * Adds an error message
   *
   * @param {string }uri the uri to show after /module/error. e.g: /module/error/missing-config
   * @param {string} title the title to show in the error message
   * @param {string} message the error message
   * @param exampleConfigObject the sample config object to show
   * @return {ErrorService}
   */
  public addErrorMessage(
    uri: string,
    title: string,
    message: string,
    exampleConfigObject: any = null
  ): ErrorService {
    ErrorService.messages[uri] = {
      title,
      message,
      exampleConfigObject
    };

    return this;
  }

  /**
   * Navigates to the error page by given error message uri.
   * E.g: foo = redirect to /module/error/foo
   *
   * @param {string} uri
   * @param {number} wait
   */
  public navigateToError(uri: string, wait = 100) {
    setTimeout(() => {
      const errorRouteIsActive = this.router.isActive('/module/error/' + uri, true);

      if (!errorRouteIsActive) {
        this.router.navigate(['module', 'error', uri]).then().catch();
      }
    }, wait);
  }

  /**
   * Returns message object by current uri
   *
   * @param {string} uri
   * @returns {string}
   */
  public getMessageObject(uri: string) {
    if (typeof ErrorService.messages[uri] === 'object') {
      return ErrorService.messages[uri];
    }
  }
}
