<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.USERS)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isSearchCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-username">
            <span class="input-group-addon">{{_(KEYS.GRID.USERNAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.email">
          </div>
          <div class="input-group qa-grid-search-first-name">
            <span class="input-group-addon">{{_(KEYS.GRID.FIRSTNAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.firstName">
          </div>
          <div class="input-group qa-grid-search-last-name">
            <span class="input-group-addon">{{_(KEYS.GRID.LASTNAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.lastName">
          </div>
          <!--<div class="input-group qa-grid-search-address">
            <span class="input-group-addon">Adresse:</span>
            <input class="form-control" [(ngModel)]="filter.address">
          </div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">

        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.USERS)}}</h5>
          </div>

          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>

      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col">{{_(KEYS.GRID.ID)}}</th>
            <th>{{_(KEYS.GRID.USERNAME_MAIL)}}</th>
            <th>{{_(KEYS.GRID.NAME)}}</th>
            <th>{{_(KEYS.GRID.ADDRESS)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_AT)}}</th>
            <th>{{_(KEYS.GRID.UPDATED_BY)}}</th>
            <th>{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let user of getUsers(); let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}" *ngIf="isOnCurrentPage(pos)">
              <td class="id-col">
                <span>{{user.userId}}</span>
              </td>
              <td>
                {{user.email}}
              </td>
              <td>
                <ng-container *ngIf="user.data">
                  <span *ngIf="user.data.gender && user.data.gender === 'male'">Herr </span>
                  <span *ngIf="user.data.gender && user.data.gender === 'female'">Frau </span>
                  <span *ngIf="user.data.title">{{user.data.title}} </span>
                  <span *ngIf="user.data.firstName">{{user.data.firstName}} </span>
                  <span *ngIf="user.data.firstName">{{user.data.lastName}}</span>
                  <span *ngIf="user.data.firstname && !user.data.firstName">{{user.data.firstname}} </span>
                  <span *ngIf="user.data.lastname && !user.data.lastName">{{user.data.lastname}}</span>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="user.data">
                  <span *ngIf="user.data.street">{{user.data.street}}, </span>
                  <span *ngIf="user.data.country">{{user.data.country}}-</span><span *ngIf="user.data.zip">{{user.data.zip}} </span>
                  <span *ngIf="user.data.city">{{user.data.city}}</span>
                </ng-container>
              </td>
              <td>
                {{user.lastChangeAt | datex:dateFormat}}
              </td>
              <td>
                {{user.lastChangeBy}}
              </td>
              <td>
                <div class="d-inline-block nowrap">
                  <div
                    class="btn btn-primary d-inline-block mr-1"
                    [routerLink]="'/module/ums/user/crud/' + user.userId"
                    ngbTooltip="{{_(KEYS.GRID.CHANGE)}}"
                  >
                    <i class="fa fa-edit qa-cursor-pointer"></i>
                  </div>
                  <div
                    class="btn btn-danger d-inline-block mr-1"
                    (click)="onDeleteUser(user)"
                    ngbTooltip="{{_(KEYS.GRID.DELETE)}}"
                  >
                    <i class="fa fa-trash qa-cursor-pointer"></i>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>

        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="getUsers().length"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>

      </div>
    </div>
  </div>
</div>
