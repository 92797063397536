import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  Route, Router, RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {LayoutService} from '@ngmedax/layout';
import {LoginService} from '../service/login.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  /**
   * List of all routes
   */
  private routeList: {[url: string]: Route} = {};

  /**
   * Injects dependencies
   */
  constructor(
    private router: Router,
    private loginService: LoginService,
    private layoutService: LayoutService) {
    for (const routeConfig of router.config) {
      this.routeList[routeConfig.path] = routeConfig;
    }
  }

  /**
   * Redirects to login if current route requires
   * authentication and user is not logged in.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | boolean}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
      // by default one must be logged in for every page
      let needsLogin = true;

      const url = '/' + route.url.join('/');

      if (route.params && route.params.authToken) {
        try {
          await this.loginService.logout();
          await this.loginService.tokenLogin(route.params.authToken);
        } catch (error) {
          console.log('token login failed for token:', route.params.authToken);
          console.error(error);
        }
      }

      // get data of current route
      const routeData = route.data ? route.data : this.getRouteData(url);

      // if route is configured to not need a logged in user
      if (typeof routeData['needsLogin'] !== 'undefined'
        && routeData['needsLogin'] === false) {
        // allow this page
        needsLogin = false;
      }

      // check if user is logged in
      const isLoggedIn = this.loginService.isLoggedIn();

      // if user is not logged in and page needs a logged in user
      if (!isLoggedIn && needsLogin) {
        // set current url as previous. we will use this to redirect when login is done
        this.loginService.setPreviousUri(url);

        // navigate to login page
        this.router.navigate(['module', 'login']).then().catch();
        return false;
      }

      // logout user on "logout" route
      if (url === '/module/login/out') {
        // logout user
        this.loginService.logout().then().catch();
        return true;
      }

      // get user object by login service
      const userObject = this.loginService.getUser();

      // if we found a user object
      if (userObject) {
        // set the username in the layout service (renders it in the user box)
        // TODO: somehow get user image
        this.layoutService.setUser({
          username: userObject.getUsername()
        });
      }

      return true;
  }

  /**
   * Returns data of current route. Returns empty object if no route data found
   *
   * @returns {any}
   */
  private getRouteData(url) {
    url = url.replace(/^\//, '');
    const route = this.routeList[url];
    return (route && route.data) ? route : {};
  }
}
