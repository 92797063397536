import {Component, Input, OnInit, Optional} from '@angular/core';

import {Role, Tenant, User, UserRole, UserTenantRoleMap, TenantToBooleanMap} from '../../../../types';
import {permission} from '../../ums.permission';
import {UmsService} from '../../services/ums.service';
import {UserService} from '../../services/user.service';

import {LayoutService} from '@ngmedax/layout';
import {PermissionService} from '@ngmedax/permission';
import {LoginService} from '@ngmedax/login';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {ConfigService} from '@ngmedax/config';

// hack to inject decorator declarations. must occur before class declaration!
export interface UserPermissionComponent extends Translatable {}

/**
 * Component to assign/unassign permissions to/of a user
 */
@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class UserPermissionComponent implements OnInit {
  /**
   * User
   * @type {User}
   */
  @Input() public user: User;

  /**
   * Tenants
   * @type {Tenant[]}
   */
  public tenants: Tenant[] = [];

  /**
   * Roles
   * @type {Role[]}
   */
  public roles: Role[] = [];

  /**
   * User tenant role map
   */
  public userTenantRoleMap: any = {};

  /**
   * Map that contains all tenants for which the user can assign user roles
   */
  public canUserManageUserRolesMap: any = {};

  /**
   * Custom name for virtual "all tenant"
   * @type {string}
   */
  public tenantAllCustomName = null;

  /**
   * Hide default tenant if it is the only one?
   * @type {boolean}
   */
  public tenantDefaultHideIfOnlyOne = false;

  /**
   * Permissions required in order to be allowed
   * to manage user role permissions in crud view
   * @type {string[]}
   */
  private canManageUserRolePermissions = [
    permission.UMS_USER_ROLE_READ,
    permission.UMS_USER_ROLE_ASSIGN,
    permission.UMS_USER_ROLE_UNASSIGN
  ];

  /**
   * Permissions required in order to be allowed
   * to display this component
   * @type {string[]}
   */
  private canDisplayComponentPermissions = [
    permission.UMS_TENANT_READ,
    permission.UMS_ROLE_READ,
    permission.UMS_USER_ROLE_READ
  ];

  /**
   * Injects dependencies
   */
  public constructor(
    private umsService: UmsService,
    private userService: UserService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    @Optional() private translationService: TranslationService,
    @Optional() private permissionService: PermissionService,
    @Optional() private loginService: LoginService
  ) {
    this.tenantDefaultHideIfOnlyOne = this.configService.get('tenant.default.hideIfOnlyOne');
    this.tenantAllCustomName = this.configService.get('tenant.all.customName');
  }

  /**
   * Loads tenants, roles and role assignments for given user.
   * Will not load when user can't display this component due to missing permissions
   */
  public ngOnInit() {
    // early bailout if user is not allowed to display this component
    if (!this.canDisplayComponent() || !this.user) {
      return;
    }

    const promises: Promise<any>[] = [
      this.umsService.loadTenants(),
      this.umsService.loadRoles(),
      this.userService.loadUserRoles(this.user)
    ];

    const cmp = this;

    Promise
      .all(promises)
      .then(result => {
        const tenants = (result[0] && result[0].length > 1 || this.tenantDefaultHideIfOnlyOne == false) ? result[0] : [];
        const roles = result[1];
        const userRoles = result[2];
        const cmp = this;

        tenants.push({
          tenantId: null,
          get name() { return cmp.tenantAllCustomName || cmp._(KEYS.CRUD.ALL) },
          get description() { return cmp._(KEYS.CRUD.ALL_TENANTS) }
        });

        // build permission and role maps
        const canUserManageUserRolesMap = this.buildCanUserManageUserRolesForTenantMap(tenants);
        const userTenantRoleMap = this.buildUserTenantRoleMap(tenants, roles, userRoles);

        this.tenants = tenants;
        this.userTenantRoleMap = userTenantRoleMap;
        this.canUserManageUserRolesMap = canUserManageUserRolesMap;
        this.roles = roles;
      })
      .catch(error => {
        alert(this._(KEYS.CRUD.ERROR_LOADING_TENANT_OR_ROLES));
        console.error(error);
      });
  }

  /**
   * Action for when a user role is changed
   *
   * @param {Tenant} tenant
   * @param {Role} role
   */
  public onUpdateUserRole(tenant: Tenant, role: Role) {
    // early bailout if user is not allowed to change role assignments
    if (!this.canUserManageUserRolesMap[tenant.tenantId]) {
      return;
    }

    const addRole = this.userTenantRoleMap[tenant.tenantId][role.roleId];
    const method = addRole ? 'assignRoles' : 'unassignRoles';

    this.layoutService.showPreloader();

    setTimeout(() => {
      this.userService[method](this.user, [{ roleId: role.roleId, tenantId: tenant.tenantId}])
        .then(() => this.layoutService.hidePreloader())
        .catch(error => {
          this.layoutService.hidePreloader();
          alert(this._(KEYS.CRUD.ERROR_SETTING_ROLE));
          console.log(error);
        });
    }, 50);
  }

  /**
   * Checks if user is allowed to display this component
   *
   * @returns {boolean}
   */
  private canDisplayComponent(): boolean {
    if (!this.permissionService) {
      return false;
    }

    let canDisplay = true;

    const isSameUser = (this.loginService && this.loginService.getUser()
      && this.loginService.getUser().getUserId() === this.user.userId);

    for (const currentPermission of this.canDisplayComponentPermissions) {
      if (!this.permissionService.isAllowed(currentPermission)) {
        // user can always read own roles
        if (isSameUser && currentPermission === permission.UMS_USER_ROLE_READ) {
          continue;
        }
        console.log('user-permissions >', 'user missing permission: ', currentPermission);
        canDisplay = false;
        break;
      }
    }
    return canDisplay;
  }

  /**
   * Builds and returns user tenant role map by given tenants, roles and user roles
   *
   * @param {Tenant[]} tenants
   * @param {Role[]} roles
   * @param {UserRole[]} userRoles
   * @returns {UserTenantRoleMap}
   */
  private buildUserTenantRoleMap(tenants: Tenant[], roles: Role[], userRoles: UserRole[]): UserTenantRoleMap {
    const userTenantRoleMap: UserTenantRoleMap = {};

    // iterate all tenants
    for (const tenant of tenants) {
      // add empty tenant object
      userTenantRoleMap[tenant.tenantId] = {};

      // init all roles in tenant object
      for (const role of roles) {
        userTenantRoleMap[tenant.tenantId][role.roleId] = false;
      }
    }

    // set tenant roles by user roles object
    for (const userRole of userRoles) {
      if (!userTenantRoleMap[userRole.tenantId]) {
        continue;
      }
      userTenantRoleMap[userRole.tenantId][userRole.roleId] = true;
    }

    return userTenantRoleMap;
  }

  /**
   * Builds and returns object with info on which tenant the user is allowed/not allowed to manager user roles
   *
   * @param {Tenant[]} tenants
   * @returns {TenantToBooleanMap}
   */
  private buildCanUserManageUserRolesForTenantMap(tenants: Tenant[]): TenantToBooleanMap {
    const canUserManageUserRolesForTenantMap: TenantToBooleanMap = {};

    // iterate all tenants
    for (const tenant of tenants) {
      // set to false and skip if permissions service is not set
      if (!this.permissionService || !this.permissionService.isAllowed) {
        canUserManageUserRolesForTenantMap[tenant.tenantId] = false;
        continue;
      }

      // default is to allow user role management
      canUserManageUserRolesForTenantMap[tenant.tenantId] = true;

      // iterate all permissions required to manage user roles
      for (const requiredPermission of this.canManageUserRolePermissions) {
        // check if current permission is allowed for current tenant
        const isAllowed = this.permissionService.isAllowed(requiredPermission, [tenant.tenantId]);

        // not allowed? set to false for this tenant
        if (!isAllowed) {
          canUserManageUserRolesForTenantMap[tenant.tenantId] = false;
          break;
        }
      }
    }

    return canUserManageUserRolesForTenantMap;
  }
}
