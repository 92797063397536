import {Injectable, Optional} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';

import {User, UserRole, UserFilterGroup} from '../../../types';
import {PasswordService} from './password.service';
import {configKeys} from '../ums.config-keys';

import {ConfigService} from '@ngmedax/config';
import {LoginService} from '@ngmedax/login';
import {AuthUriService} from './auth-uri.service';


/**
 * Service to handle user based api requests
 */
@Injectable()
export class UserService {

  /**
   * Injects dependencies
   *
   * @param {HttpClient} http
   * @param {AuthUriService} authUriService
   * @param {PasswordService} passwordService
   * @param {ConfigService} configService
   * @param {LoginService} loginService
   */
  public constructor(
    private http: HttpClient,
    private authUriService: AuthUriService,
    private passwordService: PasswordService,
    protected configService: ConfigService,
    @Optional() protected loginService: LoginService) {
  }

  /**
   * Loads Filter groups
   *
   * @returns {Promise<UserFilterGroup[]>}
   */
  public loadUserFilterGroups(userId: number): Promise<UserFilterGroup[]> {
    const basePath = this.getFilterGroupApiUrl('/user/' + userId);

    return new Promise((resolve, reject) => {
      this.http.get(basePath)
        .subscribe((userFilterGroups: any) =>
          resolve(userFilterGroups.userFilterGroup || userFilterGroups.rows || userFilterGroups || []), error => reject(error)
        );
    });
  }

  /**
   * Loads user roles by given user
   *
   * @param {User} user
   * @returns {Promise<UserRole[]>}
   */
  public loadUserRoles(user: User): Promise<UserRole[]> {
    const basePath = this.getUserApiUrl(`/${user.userId}/role`);

    return new Promise((resolve, reject) => {
      this.http.get(basePath)
        .subscribe((userRoles: any) => resolve(userRoles.rows || []), error => reject(error));
    });
  }

  /**
   * Loads users
   *
   * @returns {Promise<User[]>}
   */
  public loadUsers(): Promise<User[]> {
    // TODO: use server side paging
    const basePath = this.getUserApiUrl() + '?filter={"offset":0,"limit":100000000}';

    return new Promise((resolve, reject) => {
      this.http.get(basePath)
        .subscribe((users: any) => resolve(users.rows || []), error => reject(error));
    });
  }

  /**
   * Helper method to load user by id.
   *
   * @param {string} userId
   * @returns {Promise<User>}
   */
  public loadUser(userId: string | number): Promise<User> {
    const basePath = this.getUserApiUrl(`/${userId}`);

    return new Promise((resolve, reject) => {
      this.http.get(basePath)
        .subscribe(
          (response: any) => resolve(response.user || null),
          error => reject(error)
        );
    });
  }

  /**
   * Creates or updates a user
   *
   * @param user
   * @returns {Promise<User>}
   */
  public saveUser(user: User): Promise<User> {
    if (user.userId) {
      return this.updateUser(user);
    } else {
      return this.createUser(user);
    }
  }

  /**
   * Creates the user
   *
   * @param {User} user
   * @returns {Promise<User>}
   */
  public createUser(user: User): Promise<User> {
    const basePath = this.getUserApiUrl();

    if (user.password) {
      user.password = this.passwordService.getPasswordHash(user.password);
    }

    return new Promise((resolve, reject) => {
      this.http.post(basePath, [user])
        .subscribe(
          (response: any) => {
            const savedUser = response.rows && response.rows[0] ? response.rows[0] : null;
            const tenantId = this.configService.get('tenantId');

            // new user? allow login for this tenant!
            if (!user.userId && tenantId) {
              this
                .assignRoles(savedUser, [{roleId: 1, tenantId}])
                .then(() => resolve(savedUser))
                .catch(error => reject(error));
            } else {
              resolve(savedUser);
            }
          },
          error => reject(error));
    });
  }

  /**
   * Updates the user
   *
   * @param {User} user
   * @returns {Promise<User>}
   */
  public updateUser(user: User): Promise<User> {
    const basePath = this.getUserApiUrl(`/${user.userId}`);

    if (user.password) {
      user.password = this.passwordService.getPasswordHash(user.password);
    }

    return new Promise((resolve, reject) => {
      this.http.put(basePath, user)
        .subscribe(
          (response: any) => {
            const savedUser = response.user || null;
            resolve(savedUser);
          },
          error => reject(error));
    });
  }

  /**
   * Deletes a user
   *
   * @param {User} user
   * @returns {Promise<any>}
   */
  public deleteUser(user: User): Promise<any> {
    const basePath = this.getUserApiUrl(`/${user.userId}`);

    return new Promise<void>((resolve, reject) => {
      this.http.delete(basePath)
        .subscribe(
          () => resolve(),
          error => reject(error)
        );
    });
  }

  /**
   * Assigns roles to a user
   *
   * @param {User} user
   * @param {number[]} roles
   * @returns {Promise<User>}
   */
  public assignRoles(user: User, roles: {roleId: number; tenantId?: number | null}[]): Promise<User> {
    const basePath = this.getUserApiUrl(`/${user.userId}/role`);

    return new Promise((resolve, reject) => {
      this.http.post(basePath, roles)
        .subscribe(
          (response: any) => resolve(response.rows || []),
          error => reject(error)
        );
    });
  }

  /**
   * Unassigns roles from a user
   *
   * @param {User} user
   * @param {number[]} roles
   * @returns {Promise<User>}
   */
  public unassignRoles(user: User, roles: {roleId: number; tenantId?: number | null}[]): Promise<User> {
    const basePath = this.getUserApiUrl(`/${user.userId}/role`);

    return new Promise((resolve, reject) => {
      this.http.request('DELETE', basePath, { body: roles }).subscribe(
        (response: any) => resolve(response.userFilterGroup || response.rows || []),
        (error) => reject(error)
      );
    });
  }

  /**
   * Assigns filter groups to a user
   *
   * @param {User} user
   * @param {{id: string}} filterGroups
   * @returns {Promise<UserFilterGroup>}
   */
  public assignFilterGroups(user: User, filterGroups: {id: string}[]): Promise<UserFilterGroup[]> {
    const basePath = this.getFilterGroupApiUrl(`/user/${user.userId}`);

    return new Promise((resolve, reject) => {
      this.http.post(basePath, filterGroups)
        .subscribe(
          (response: any) => resolve(response.userFilterGroup || response.rows || response || []),
          error => reject(error)
        );
    });
  }

  /**
   * Unassigns filter groups from a user
   *
   * @param {User} user
   * @param {{id: string}} filterGroups
   * @returns {Promise<UserFilterGroup>}
   */
  public unassignFilterGroups(user: User, filterGroups: {id: string}[]): Promise<UserFilterGroup[]> {
    const basePath = this.getFilterGroupApiUrl(`/user/${user.userId}`);

    return new Promise((resolve, reject) => {
      this.http.request('DELETE', basePath, { body: filterGroups }).subscribe(
        (response: any) => resolve(response.userFilterGroup || response.rows || []),
        (error) => reject(error)
      );
    });
  }

  /**
   * Returns api url for filter group
   *
   * @returns {string}
   */
  private getFilterGroupApiUrl(suffix: string = null): string {
    return this.configService.get(configKeys.FILTER_GROUP_URI_CONFIG_KEY)
      + (suffix ? suffix : '')
      + this.authUriService.getAuthUri(this.loginService, true);
  }

  /**
   * Returns api url for user
   *
   * @returns {string}
   */
  private getUserApiUrl(suffix: string = null): string {
    return this.getUrlWithAuth(configKeys.USER_URI_CONFIG_KEY, suffix);
  }

  /**
   * Returns url with auth uri part by given config key
   *
   * @param {string} configKey
   * @param {any} suffix
   * @returns {string}
   */
  private getUrlWithAuth(configKey: string, suffix = null) {
    return this.authUriService.getUrlWithAuth(
      this.loginService,
      configKeys.BASE_URI_CONFIG_KEY,
      configKey,
      suffix
    );
  }
}
