import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/login/out',
    component: LoginComponent,
    canActivate: ['CoreGuard'],
    data: { needsLogin: true }
  },
  {
    path: 'module/login',
    canActivate: ['CoreGuard'],
    component: LoginComponent,
    data: { needsLogin: false }
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
