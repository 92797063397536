import {Injectable} from '@angular/core';


declare const window: any;

/**
 * Service to access query string params
 */
@Injectable()
export class QueryStringService {
  private params: {[key: string]: any} = {};

  /**
   * Initializes new query string object and parses query string
   */
  public constructor() {
    this.parse();
  }

  /**
   * Parses query string from param or window.location into this object
   *
   * @param {string} [queryString=null]
   */
  public parse(queryString: string = null) {
    if (!queryString && (!window || !window.location || !window.location.search || window.location.search[0] !== '?')) {
      return;
    }

    const toParams = (pairs: string[]): any => {
      const params: any = {};

      for (const pair of pairs) {
        const [key, value] = pair.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value);
      }

      return params;
    };

    const pairs: string[] = (queryString || window.location.search.substr(1)).split('&');
    const params = toParams(pairs);
    this.params = params;
  }

  /**
   * Returns value for given key
   *
   * @param {string} key
   * @returns {any}
   */
  public getParam(key: string): any {
    if (this.params[key]) {
      return this.params[key];
    }
  }

  /**
   * Returns object will all query string params and their value
   *
   * @returns {{[key: string]: any}}
   */
  public getParams() {
    return this.params;
  }

  /**
   * Resets params
   */
  public reset() {
    this.params = [];
  }
}
