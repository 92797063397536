import {Injectable, Optional} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DATE_FORMAT_YMD, DateFormatService, TranslationService} from '@ngmedax/translation';

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}


@Injectable()
export class NgbDateDeParserFormatter extends NgbDateParserFormatter {
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatter: DateFormatService
  ) {
    super();
  }

  parse(value: string): NgbDateStruct {
    return null;
  }

  format(date: NgbDateStruct): string {
    let stringDate = '';

    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + '.' : '';
      stringDate += isNumber(date.month) ? padNumber(date.month) + '.' : '';
      stringDate += date.year;
    }

    if (this.translationService) {
      const opts = {
        fromLocale: 'de_DE',
        toLocale: this.translationService.getLocale(),
        dateFormat: DATE_FORMAT_YMD,
        date: stringDate
      };
      return this.dateFormatter.convert(opts);
    }

    return stringDate;
  }
}
