import {Injectable, Optional} from '@angular/core';
import {LoginService} from '@ngmedax/login';
import {ConfigService} from '@ngmedax/config';
import {PermissionConfigService} from './permission.config.service';

@Injectable()
export class PermissionService {
  /**
   * Injects login and config service
   * @param {LoginService} loginService
   * @param {ConfigService} configService
   */
  public constructor(
    @Optional() private loginService: LoginService,
    private permissionConfigService: PermissionConfigService,
    private configService: ConfigService
  ) {
  }

  /**
   * Checks if a user is allowed to perform an action for the given scopes
   *
   * @param {string} action
   * @param {Array|null} requiredScopes
   * @returns {boolean}
   */
  public isAllowed(action: string, requiredScopes: any[] = null) {
    // if login service not found (login module not loaded)
    if (!this.loginService) {
      // allow access
      return true;
    }

    // no required scopes given = no scopes necessary!
    if (!requiredScopes) {
      // set to empty array
      // TODO: add tenant id from config
      requiredScopes = [];
    }

    // get currently active tenant id
    const activeTenantId = this.permissionConfigService.getTenantId();

    // if we found an active tenant id
    if (activeTenantId) {
      // push it to the required scopes
      requiredScopes.push(activeTenantId);
    }

    // get user object by login service
    const user = this.loginService.getUser();

    // if no user object found
    if (!user) {
      // no login = user is not allowed
      return false;
    }

    // get allowed actions for user
    const actions = user.getPermissions();

    // if no action are allowed for this user
    if (!actions || action.length === 0) {
      // no actions = nothing allowed for this user
      return false;
    }

    // get scopes for allowed action (allowed tenant id's)
    const allowedScopes = actions[action];

    // return false if scopes for this action is empty
    if (!allowedScopes) {
      return false;
    }

    // return true if user can perform this action for all tenants
    if (typeof allowedScopes['*'] !== 'undefined') {
      return true;
    }

    // access is allowed by default
    let isAllowed = true;

    // iterate each required scope to find out if user
    // is allowed to perform action for the required scopes
    for (let index = 0; index < requiredScopes.length; index++) {

      // get current required scope id by current index
      let requiredScope = requiredScopes[index];

      // if current required scope is null
      if (!requiredScope) {
        // user needs to have permission on all scopes
        requiredScope = '*';
      }

      // set allowed to false and break if current scope is not allowed
      if (typeof allowedScopes[requiredScope] === 'undefined') {
        isAllowed = false;
        break;
      }
    }

    return isAllowed;
  }

  /**
   * Checks if a route is allowed by route data
   *
   * @param routeData
   * @returns {boolean}
   */
  public isAllowedByRouteData(routeData: any): {allowed: boolean, requiredScopes?: any[], requiredAction?: string} {

    // TODO: check if we can actually do this
    // route data is sometimes missing
    if (!routeData) {
      return {allowed: true};
    }

    const requiredConfig = routeData['needsConfig'];

    if (Array.isArray(requiredConfig) && requiredConfig.length) {
      for (const key of requiredConfig) {
        if (!this.configService.get(key)) {
          return {allowed: false, requiredScopes: [key], requiredAction: 'config'};
        }
      }
    }

    // get required permissions, if any set
    const requiredPermissions = routeData['needsPermissions'] || routeData['needsActions'];

    // if current route has required permissions
    if (requiredPermissions) {
      // iterate all required permissions
      for (const action of requiredPermissions) {
        // default values
        let requiredScopes = [];
        let requiredAction = 'UNKNOWN';

        // current permission can be an object with defined scopes
        if (typeof action === 'object') {
          // get scopes for current permission
          requiredScopes = action['scopes'] || [];
          // get required permission (action) for current permission
          requiredAction = action['permission'] || action['action'] || 'UNKNOWN';
        }

        // current permission can also be a string (no extra scopes required)
        if (typeof action === 'string') {
          requiredScopes = [];
          requiredAction = action;
        }

        // check if user is allowed to perform current action in current scopes
        const isAllowed = this.isAllowed(requiredAction, requiredScopes);

        // if user is not allowed to access this page
        if (!isAllowed) {
          return {allowed: false, requiredScopes, requiredAction};
        }
      }
    }

    return {allowed: true};
  }

}
