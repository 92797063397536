/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you want to change the text.
 * Instead, change the de_DE translations!
 */
export const KEYS = {
  DEFAULT: {
    FEATURES: "Features",
    LIMITATIONS: "Einschränkungen",
    LICENSEE: "Lizenznehmer",
    PARTNER: "Partner",
    EXPIRE_DATE: "Ablaufdatum",

    EXTENDED_WYSIWYG_FUNCTIONS: "Erweiterte WYSIWYG Funktionen",
    MAIL_OPTION_AFTER_SUBMIT: "Mail Option nach Abgabe",
    SEND_FILL_LINK_VIA_MAIL: "Ausfüll App Link per Mail senden",
    COPY_APP_LINK: "Ausfüll App Link kopieren",
    DIRECT_FILL: "Direkt ausfüllen",
    ANON_PATIENTS: "Anonyme Patienten",
    PATIENT_UPLOAD: "Patienten Upload",
    PDF_FORMS: "PDF Formulare",
    SIGNO_SIGN: "SignoSign Unterschriften",

    PERMITTED_DOMAINS: "Erlaubte Domains",
    NUM_QUESTIONNAIRES: "Anzahl Fragebögen",
    NUM_DEVICES: "Anzahl Geräte",
    NUM_SURVEYS_PER_MONTH: "Abgaben pro Monat"
  },
  LICENSE_MANAGER: {
    GRID: {
      ID: "Id",
      CREATED_AT: "Erstellt am",
      UPDATED_AT: "Bearbeitet am",
      CREATED_BY: "Erstellt von",
      UPDATED_BY: "Bearbeiten von",
      VALID_UNTIL: "Gültig bis",
      ENTRIES: "Einträge",
      OPTIONS: "Optionen",
      SEARCH: "Suche",
      LICENSES: "Lizenzen",
      ERROR_LOADING_LICENSES: "Beim Laden der Einträge ist ein Fehler aufgetreten!",
    },
    CRUD: {
      HELP_TEXT_FEATURES: "Hiermit kann die Funktionalität der Systemverwaltung verwaltet werden.",
      HELP_TEXT_LIMITATIONS: "Werden global angewendet. Leer lassen = keine Beschränkung.",
      HELP_TEXT_LICENSEE: "Lizenznehmer auf den die Lizenz ausgestellt werden soll.",
      HELP_TEXT_PASSWORD: "Passwort um den privaten Schlüssel zu entsperren.",
      HELP_TEXT_EXPIRE_DATE: "Ablaufdatum der Lizenz. Leer lassen für unbegrenzte Gültigkeit.",
      LICENSE: "Lizenz",
      PASSWORD: "Passwort",
      GENERATE: "Generieren",
      RESET: "Zurücksetzen",
      SAVE: "Speichern"
    }
  }
};

