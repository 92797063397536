<div class="error-overlay floating-image" *ngIf="title && message">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center text-white mb-4"><br><br></h2>
        <div class="row">
          <div class="col-md-5 mx-auto">
            <div class="card rounded-2 animated fadeIn">
              <div class="card-header alert-danger">
                <i class="fa fa-exclamation-triangle"></i>
                <h3 class="mb-0">{{title}}</h3>
              </div>
              <div class="card-body">
                <div [innerHTML]="message"></div>
                <div *ngIf="exampleConfig">
                  <span class="example">&nbsp;</span>
                  <pre><code [innerHTML]="exampleConfig"></code></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
