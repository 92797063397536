import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class ThemeService {
  private css: string;
  private cssLoadingFailed = false;
  private cssFilePath: string;
  private logo: string;
  private title: string;
  private favicon: string;

  public constructor(private http: HttpClient) {}

  public load(cssFilePath: string) {
    this.cssFilePath = cssFilePath;

    return new Promise<void>((resolve) => {
      this.http.get(cssFilePath, {responseType: 'text' })
        .subscribe(
          response => {
            this.css = <string>response;
            resolve();
          },
          error => {
            this.cssLoadingFailed = true;
            resolve();
          });
    });
  }

  public getCss() {
    return this.css;
  }

  public getCssFilePath() {
    return this.cssFilePath;
  }

  public setLogo(logo: string) {
    this.logo = logo;
  }

  public getLogo() {
    return this.logo;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public getTitle() {
    return this.title;
  }

  public setFavicon(favicon: string) {
    this.favicon = favicon;
  }

  public getFavicon() {
    return this.favicon;
  }

  public hasFailedToLoadCss() {
    return this.cssLoadingFailed;
  }
}
