import {Component, OnInit, Optional} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import {LayoutService} from '@ngmedax/layout';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {PasswordPolicy} from '../../../../types';
import {UmsService} from '../../services/ums.service';
import {PasswordService} from '../../services/password.service';
import {AuthTokenService} from '../../services/auth-token.service';
import {passwordValidators} from '../change-password/password.validators';

import {TRANSLATION_AUTH_TOKEN_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';

// hack to inject decorator declarations. must occur before class declaration!
export interface AuthTokenComponent extends Translatable {}

@Component({
  selector: 'app-auth-token',
  templateUrl: './auth-token.component.html',
  styleUrls: ['./auth-token.component.css']
})
@Translatable({scope: TRANSLATION_AUTH_TOKEN_SCOPE, keys: KEYS})
export class AuthTokenComponent implements OnInit {
  /**
   * Auth token form
   * @type {FormGroup}
   */
  public authTokenForm: FormGroup;

  /**
   * Password policy
   * @type {PasswordPolicy}
   */
  public passwordPolicy: PasswordPolicy;

  /**
   * Member for generated auth token
   * @type {string}
   */
  public authToken: string;

  /**
   * Member for info if generated auth token is valid or not
   * @type {boolean}
   */
  public isValidAuthToken = false;

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private formBuilder: FormBuilder,
    private umsService: UmsService,
    private passwordService: PasswordService,
    private authTokenService: AuthTokenService,
    private layoutService: LayoutService) {
  }

  public ngOnInit() {
    const fb = this.formBuilder;

    const pwValidators = [
      Validators.required,
      passwordValidators.passwordPolicyValidator()
    ];

    this.authTokenForm = fb.group({
      'email': fb.control(null, [Validators.required, Validators.email]),
      'password': fb.control(null, pwValidators),
      'secret': fb.control(null, Validators.required)
    });

    // set password policy on user form. validators will need this
    this.passwordPolicy = this.passwordService.getPasswordPolicy();
    (<any>this.authTokenForm).passwordPolicy = this.passwordPolicy;
  }

  public onSubmit() {
    this.layoutService.showPreloader();

    const failed = (error) => {
      console.log(error);
      alert(this._(KEYS.AUTH_TOKEN.ERROR_CHECKING_AUTH_TOKEN));
      this.layoutService.hidePreloader();
    };

    try {
      const formValues: any = this.authTokenForm.value;
      const passwordHash = this.passwordService.getPasswordHash(formValues.password);
      const username = formValues.email;
      const secret = formValues.secret;

      const authToken = this.authTokenService.encrypt(username, passwordHash, secret);

      this.umsService
        .isValidAuthToken(authToken)
        .then((isValidAuthToken: boolean) => {
          this.isValidAuthToken = isValidAuthToken;
          this.authToken = authToken;
          this.layoutService.hidePreloader();
        })
        .catch(error => failed(error));
    } catch (error) {
      failed(error);
    }
  }

  /**
   * Returns "email" form control
   * @returns {any}
   */
  get email(): any {
    return this.authTokenForm.get('email');
  }

  /**
   * Returns "password" form control
   * @returns {any}
   */
  get password(): any {
    return this.authTokenForm.get('password');
  }
}
