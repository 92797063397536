import {Injectable} from '@angular/core';


@Injectable()
export class ValueService {
  /**
   * Should we perform a full json check?
   * @type {boolean}
   */
  private isJSONParseCheckEnabled = false;

  /**
   * Returns true if given string seems to be valid json
   *
   * @param value
   * @returns {boolean}
   */
  public isJSON(value: any): boolean {
    // "..." is valid json
    if (value.match(/^".*?"$/)) {
      return true;
    }

    // does not look like json
    if (typeof value !== 'string' || ! /^[\[|\{](\s|.*|\w)*[\]|\}]$/.test(value)) {
      return false;
    }

    // broken json object
    if (/\[object/.test(value)) {
      return false;
    }

    // full json check via parse is very expensive!
    if (this.isJSONParseCheckEnabled) {
      try {
        JSON.parse(value);
      } catch (error) {
        return false;
      }
    }

    return true;
  }

  /**
   * Returns value from object by given path array
   *
   * @param object
   * @param {any[]} path
   * @returns {any}
   */
  public get(object, path: any[]) {
    object = this.isUndefined(object) ? {} : object;
    return path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, object);
  }

  /**
   * Returns true if given value is a function
   *
   * @param value
   * @returns {boolean}
   */
  public isFunction(value: any): boolean {
    return typeof value === 'function';
  }

  /**
   * Returns true if given value is an object. Note: Arrays are also
   * objects, so this method will also return true for arrays!
   *
   * @param value
   * @returns {boolean}
   */
  public isObject(value: any): boolean {
    return typeof value === 'object';
  }

  /**
   * Returns true if given value is an array
   *
   * @param value
   * @returns {boolean}
   */
  public isArray(value: any): boolean {
    return Array.isArray(value);
  }

  /**
   * Returns true if given value is a string
   *
   * @param value
   * @returns {boolean}
   */
  public isString(value: any): boolean {
    return typeof value === 'string';
  }

  /**
   * Returns true if given value is a number
   *
   * @param value
   * @returns {boolean}
   */
  public isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  /**
   * Returns true if given value is a boolean
   *
   * @param value
   * @returns {boolean}
   */
  public isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  /**
   * Returns true if given value is null
   *
   * @param value
   * @returns {boolean}
   */
  public isNull(value: any): boolean {
    return value === null;
  }

  /**
   * Returns true if given value is undefined
   *
   * @param value
   * @returns {boolean}
   */
  public isUndefined(value: any): boolean {
    return typeof value === 'undefined';
  }

  /**
   * Returns true if given value is a symbol
   *
   * @param value
   * @returns {boolean}
   */
  public isSymbol(value: any): boolean {
    return typeof value === 'symbol';
  }

  /**
   * Returns true if given value is a primitive type
   *
   * @param value
   * @returns {boolean}
   */
  public isPrimitive(value: any): boolean {
    return (
      this.isString(value) ||
      this.isNumber(value) ||
      this.isBoolean(value) ||
      this.isNull(value) ||
      this.isUndefined(value) ||
      this.isSymbol(value)
    );
  }

  /**
   * Clones the given object
   *
   * @param {any} obj
   * @returns {any}
   */
  public clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Enables or disables full json parse check
   *
   * @param isJSONParseCheckEnabled {boolean}
   * returns {void}
   */
  public enableJSONParseCheck(isJSONParseCheckEnabled: boolean): void {
    this.isJSONParseCheckEnabled = isJSONParseCheckEnabled;
  }
}
