import {Converter} from './service/converter';
import {Signer} from './service/signer';
import {LicenseDecoder} from './service/licence/decoder';
import {LicenseGenerator} from './service/licence/generator';
import {Nonce} from './service/nonce';
import {LicenseValidator} from './service/licence/validator';


class Factory {
  private converter = new Converter();
  private signer = new Signer(this.converter);
  private nonce = new Nonce();
  private decoder = new LicenseDecoder(this.converter, this.signer);

  public getLicenseDecoder(): LicenseDecoder {
    return new LicenseDecoder(this.converter, this.signer);
  }

  public getLicenseValidator(): LicenseValidator {
    return new LicenseValidator(this.decoder);
  }

  public getLicenseGenerator(): LicenseGenerator {
    return  new LicenseGenerator(this.signer, this.converter, this.nonce);
  }
}

export const factory = new Factory();
