/**
 * The public key is hidden in code, to make it extra hard for crackers to patch the binary!
 * Happy Hacking ;p
 */
const fn1 = [102, 114, 111, 109, 67, 104, 97, 114, 67, 111, 100, 101].map(v => String.fromCharCode(v)).join('');
const fn2 = [109, 97, 112].map(v => String[fn1](v)).join('');
const fn3 = [106, 111, 105, 110][fn2](v => String[fn1](v)).join('');
const fn4 = [115, 117, 98, 115, 116, 114][fn2](v => String[fn1](v))[fn3]('');
const fn5 = [114, 101, 100, 117, 99, 101][fn2](v => String[fn1](v))[fn3]('');
const fn6 = [102, 114, 111, 109][fn2](v => String[fn1](v))[fn3]('');
const st1 = [98, 97, 115, 101, 54, 52][fn2](v => String[fn1](v))[fn3]('');

const c1 = [119, 120, 56, 110, 117, 85, 51, 67, 76, 109, 87, 61][fn2](v => String[fn1](v))[fn3]('')[fn4](5, 3);
const c2 = [48, 53, 115, 104, 55, 49, 101, 113, 107, 51, 108, 86, 76, 101, 43, 109, 61][fn2](v => String[fn1](v))[fn3]('')[fn4](3, 6);
const c3 = [109, 76, 115, 85, 51, 98, 114, 76, 52, 53, 108, 118, 79, 89, 53, 120, 54, 111, 115]
  [fn2](v => String[fn1](v))[fn5]((a, c, i) => i % 2 === 0 ? a + c : a);

const c4 = [86, 90, 132, 145, 193, 204, 181][fn2]((v, i) => String[fn1](v - (i * 23)))[fn3]('');

const c5 = [101, 190, 269, 209, 373, 490, 328, 687, 770, 498, 876, 1005, 640, 1127, 1253, 746, 1381, 1438]
  [fn2]((v, i) => String[fn1](v - (i * (i % 3 === 0 ? 46 : 81) )))[fn3]('');

export const PUBLIC_KEY = new Uint8Array(Buffer[fn6](c1 + c2 + c3 + c4 + c5, st1));
