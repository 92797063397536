import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {TranslationService} from './translation.service';
import {DateFormatOptions} from '../../types';

@Injectable()
export class DateFormatService {
  /**
   * MySQL Date format
   */
  private readonly DATE_FORMAT_MYSQL = 'YYYY-MM-DD';

  /**
   * Injects dependencies
   */
  public constructor(
    private translationService: TranslationService) {
  }

  /**
   * Converts date from one format to another format
   *
   * @param {DateFormatOptions} opts
   * @returns {string}
   */
  public convert(opts: DateFormatOptions): string {
    !opts && (opts = {date: null, fromFormat: null, toFormat: null});

    if (!opts.date) {
      return null;
    }

    if (opts.fromLocale && opts.toLocale && opts.dateFormat) {
      opts.fromFormat = this.translationService.getDateFormat(opts.dateFormat, opts.fromLocale);
      opts.toFormat = this.translationService.getDateFormat(opts.dateFormat, opts.toLocale);
    }

    if (!opts.fromFormat || !opts.toFormat) {
      return null;
    }

    const formattedDate = moment(opts.date, opts.fromFormat).format(opts.toFormat);
    return (formattedDate !== 'Invalid date') ?  formattedDate : opts.date;
  }

  /**
   * Converts given date to msyql date
   *
   * @param {string} date
   * @param {string} dateFormat
   * @returns {string}
   */
  public toMySqlDate(date: string, dateFormat: string): string {
    return this.convert({date, fromFormat: dateFormat, toFormat: this.DATE_FORMAT_MYSQL});
  }

  /**
   * Converts given mysql date to target date format
   *
   * @param {string} date
   * @param {string} dateFormat
   * @returns {string}
   */
  public fromMySqlDate(date: string, dateFormat: string): string {
    return this.convert({date, fromFormat:  this.DATE_FORMAT_MYSQL, toFormat: dateFormat});
  }
}
