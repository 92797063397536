import {NgModule} from '@angular/core';
import {ValueService} from './value.service';


@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    ValueService
  ]
})
export class ValueModule {
}
