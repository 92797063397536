import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RegistryService} from '@ngmedax/registry';
import {LoginService} from '../service/login.service';


@Injectable()
export class RegistryGuard implements CanActivate {

  /**
   * Injects dependencies
   */
  constructor(
    private loginService: LoginService,
    private registryService: RegistryService
  ) {
  }

  /**
   * Adds auth token to registry, if user is logged in and has an auth token
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | boolean}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const user = this.loginService.getUser();

    if (user && user.getToken()) {
      this.registryService.set('session.authToken', user.getToken());
    }

    return true;
  }
}
