<div class="permissions">
  <ngb-accordion [closeOthers]="true">
    <ngb-panel
      *ngFor="let tenant of tenants"
      [disabled]="!user || !user.userId">
      <ng-template ngbPanelHeader>
        <span class="tenant-tabs">
          <div class="btn-toolbar justify-content-between">
            <div class="justify-content-between qa-lh-2p5 ml-2">
              <i class="fa fa-copy"></i>
              {{_(KEYS.CRUD.TENANT)}}: {{tenant.name}}
            </div>

            <button ngbPanelToggle class="btn btn-primary d-inline-block">
              <i class="fa toggle"></i>
            </button>
          </div>
        </span>
      </ng-template>

      <ng-template ngbPanelContent>
        <h3>{{_(KEYS.CRUD.ROLES)}}</h3>
        <table class="table">
          <thead>
          <tr>
            <th>{{_(KEYS.CRUD.ROLE)}}</th>
            <th>{{_(KEYS.CRUD.DESCRIPTION)}}</th>
            <th>
              <ng-container *ngIf="canUserManageUserRolesMap[tenant.tenantId]">
                {{_(KEYS.CRUD.ASSIGN)}}
              </ng-container>
              <ng-container *ngIf="!canUserManageUserRolesMap[tenant.tenantId]">
                {{_(KEYS.CRUD.ASSIGNED)}}
              </ng-container>
            </th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let role of roles; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td>
                {{role.name}}
              </td>
              <td>
                {{role.description}}
              </td>
              <td>
                <input
                  type="checkbox"
                  (change)="onUpdateUserRole(tenant, role)"
                  [(ngModel)]="userTenantRoleMap[tenant.tenantId][role.roleId]"
                  [attr.disabled]="canUserManageUserRolesMap[tenant.tenantId] === false ? false : null">
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
