import {FormControl} from '@angular/forms';

/**
 * Password validators
 */
export const passwordValidators = {
    /**
     * Higher order function to get validator that checks if new passwords matches.
     * You must provide the "passwordField" to match against new password.
     *
     * @param {string} passwordField
     * @returns {(control: FormControl) => any}
     */
    passwordsMatchValidator: (passwordField: string): (control: FormControl) => any => {
      return (control: FormControl) => {
        let pw = '';

        const isFormValueObjectSet = (control.parent && control.parent.value);

        if (isFormValueObjectSet) {
          pw = control.parent.value[passwordField];
        }

        const passwordsDoNotMatch = (control.value && control.value !== pw);

        if (passwordsDoNotMatch) {
          // returns error code
          return {passwordsMatch: true};
        }

        // return null = validation passed
        return null;
      };
    },

    /**
     * Validator to check if old password is correct. Only works in conjunction
     * with the "onResetOldPasswordCheckTimeout" event handler
     *
     * @param {FormControl} control
     * @returns {Promise<any>}
     */
    oldPasswordValidator: (control: FormControl): Promise<any> => {
      return new Promise<any>((resolve) => {

        const isFormAvailable = (control.parent);

        if (isFormAvailable) {
          const interval = setInterval(() => {
            const oldPw = (<any>control.parent).oldPassword;
            if (oldPw) {
              clearInterval(interval);
              resolve(oldPw.isCorrect ? null : {oldPassword: true});
            }
          }, 200);
        }
      });
    },

    /**
     * Higher order function to get validator that checks if password policy matches.
     * You must provide the "passwordRepeatField" so the validator can reset it.
     *
     * @param {string} passwordRepeatField
     * @returns {(control: FormControl) => any}
     */
    passwordPolicyValidator: (passwordRepeatField: string = ''): (control: FormControl) => any => {
      return (control: FormControl) => {
        let validationResult = null;

        const isFormValueObjectSet = (control.parent && control.parent.value);
        const isValueSet = (control.value && control.value.length);

        if (isFormValueObjectSet && isValueSet) {
          const passwordPolicy = (<any>control.parent).passwordPolicy;

          for (const regex of passwordPolicy.regex) {
            const currentPasswordPolicyDoesNotMatch = !control.value.match(regex);

            if (currentPasswordPolicyDoesNotMatch) {
              validationResult = {passwordPolicy: true};
              break;
            }
          }

          if (passwordRepeatField) {
            control.parent.get(passwordRepeatField).reset();
          }
        }

        return validationResult;
      };
    }
};
