import {PUBLIC_KEY} from '../../../../constants';
import {License} from '../../../../types';
import {LicenseDecoder} from './decoder';
import {KeyPair} from '../key-pair';


/**
 * License validator
 */
export class LicenseValidator {

  /**
   * Public key to validate license key
   * @var {string} publicKey
   */
  private readonly publicKey = PUBLIC_KEY;

  /**
   * Initializes new license validator
   *
   * @param decoder
   */
  public constructor(private decoder: LicenseDecoder) {
  }

  /**
   * Returns true if given license is valid
   *
   * @param {string} licenseKey
   */
  public isValid(license: string | License): boolean {
    try {
      typeof license === 'string' && (license = this.decode(license));

      if (!license) {
        return false;
      }

      if (this.isExpired(license)) {
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Returns true if license key is expired
   *
   * @param {string} licenseKey
   * @returns {boolean}
   */
  public isExpired(license: License | string) {
    if (typeof license === 'string') {
      license = this.decode(license);
    }

    if (license.validUntil && Date.now() > Date.parse(license.validUntil)) {
      return true;
    }

    return false;
  }

  /**
   * Decodes license
   *
   * @param {string} licenseKey
   * @returns {License}
   */
  private decode(licenseKey: string): License {
    const keyPair = new KeyPair(null, this.publicKey);
    return this.decoder.decode(licenseKey, keyPair);
  }
}
