import {Injectable} from '@angular/core';
import {ConfigService} from '@ngmedax/config';

@Injectable()
export class TenantConfigService {
  /**
   * Injects config service
   * @param {ConfigService} configService
   */
  public constructor(private configService: ConfigService) {}

  /**
   * Returns the tenant id for the currently active domain
   * @returns {Object}
   */
  public getTenantId(): number {
    // config service auto resolves by domain
    return this.configService.get('tenantId');
  }
}
