import {Injectable, Optional} from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  Route, Router, RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {ErrorService} from '@ngmedax/error';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {PermissionService} from '../permission.service';
import {KEYS} from '../../../translation-keys';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';


// hack to inject decorator declarations. must occur before class declaration!
export interface AuthorizationGuard extends Translatable {}
declare const $: any;

@Injectable()
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class AuthorizationGuard implements CanActivate {

  /**
   * List of all routes
   */
  private routeList: {[url: string]: Route} = {};

  /**
   * Injects dependencies
   */
  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private errorService: ErrorService,
    @Optional() private translationService: TranslationService
  ) {
    for (const routeConfig of router.config) {
      this.routeList[routeConfig.path] = routeConfig;
    }
  }

  /**
   * Redirects to "insufficient-permissions" error page, when user
   * is not allowed to access current route
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | boolean}
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const url = '/' + route.url.join('/');

    // get data of current route
    const routeData = route.data ? route.data : this.getRouteData(url);

    // check if user is allowed to perform current action in current scopes
    const result = this.permissionService.isAllowedByRouteData(routeData);

    // if user is not allowed to access this page
    if (!result.allowed) {
      this.hideGui();

      // show error message
      this.showError(result.requiredAction, url);
      
      setTimeout(() => this.showGui(), 200);
      return false;
    }

    this.showGui();
    return true;
  }

  /**
   * Returns data of current route. Returns empty object if no route data found
   *
   * @returns {any}
   */
  private getRouteData(url) {
    url = url.replace(/^\//, '');
    const route = this.routeList[url];
    return (route && route.data) ? route : {};
  }

  /**
   * Shows 'insufficient permission' error
   *
   * @param {string} action
   * @param {string} currentUri
   */
  private showError(action: string, currentUri: string) {
    const uri = 'insufficient-permissions';
    const title = this._(KEYS.DEFAULT.ACCESS_DENIED);
    const message = this._(KEYS.DEFAULT.MISSING_MANDATORY_PERMISSION, {action, currentUri});

    this.errorService
      .addErrorMessage(uri, title, message)
      .navigateToError(uri, 0);
  }

  private hideGui() {
    if ($ && typeof $ === 'function') {
      $('body').hide();
    }
  }

  private showGui() {
    if ($ && typeof $ === 'function') {
      $('body').show();
    }
  }
}
