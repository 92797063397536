<div class="qa-text-options">
  <b>&nbsp;&nbsp;{{_(KEYS.EDITOR.VALIDATION)}}</b>
  <table style="width: 100%">
    <tr #regexEl>
      <td>
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-random"></i></span>
          <input class="form-control" type="text" placeholder="{{_(KEYS.EDITOR.REGULAR_EXPRESSION)}}" [(ngModel)]="regex" />
        </div>
      </td>
      <td style="width: 75px">
        <input class="form-control" type="text" placeholder="Mod." [(ngModel)]="mod"/>
      </td>
      <td>
        <button class="btn btn-success ml-1" (click)="onSaveRegex(regexEl)" [disabled]="!isRegexDirty() ? true : null">
          <i class="fa fa-save"></i>
        </button>
        <button class="btn btn-primary ml-1" (click)="onResetRegex(regexEl)">
          <i class="fa fa-undo"></i>
        </button>
        <button class="btn btn-danger ml-1" (click)="onDeleteRegex()" [disabled]="!isRegexDirty() ? true : null">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr #hintEl>
      <td colspan="2">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-lightbulb-o"></i></span>
          <input class="form-control" type="text" placeholder="{{_(KEYS.EDITOR.HINT)}}" [(ngModel)]="hint"/>
        </div>
      </td>
      <td>
        <button class="btn btn-success ml-1" (click)="onSaveHint(hintEl)" [disabled]="!isHintDirty() ? true : null">
          <i class="fa fa-save"></i>
        </button>
        <button class="btn btn-primary ml-1" (click)="onResetHint(hintEl)">
          <i class="fa fa-undo"></i>
        </button>
        <button class="btn btn-danger ml-1" (click)="onDeleteHint()" [disabled]="!isHintDirty() ? true : null">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  </table>
</div>
