import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RegistryService} from '@ngmedax/registry';
import {TenantService} from '../tenant.service';


@Injectable()
export class RegistryGuard implements CanActivate {

  /**
   * Injects dependencies
   */
  constructor(
    private tenantService: TenantService,
    private registryService: RegistryService
  ) {
  }

  /**
   * Adds tenant id to registry
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | boolean}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const tenantId = this.tenantService.getTenantId();

    if (tenantId) {
      this.registryService.set('session.tenantId', tenantId);
    }

    return true;
  }
}
