export class UserLoginModel {
  /**
   * Username of logged in user
   */
  private username: string;

  /**
   * UMS API token
   */
  private token: string;

  /**
   * User id
   */
  private userId: number;

  /**
   * Session valid until date
   */
  private validUntil: Date;

  /**
   * Permissions
   */
  private permissions: any[];

  /**
   * Data
   */
  private data: any;

  /**
   * Inits new user login model
   *
   * @param {string} username
   * @param {string} token
   * @param {string} userId
   * @param {string} validUntil
   * @param {any[]} permissions
   * @param {any} data
   */
  public constructor(
    username: string,
    token: string,
    userId: string,
    validUntil: string,
    permissions: any[],
    data?: any
  ) {
    this.username = username;
    this.token = token;
    this.userId = parseInt(userId, 10);
    this.validUntil = new Date(validUntil);
    this.permissions = permissions;
    this.data = data;
  }

  getUsername(): string {
    return this.username;
  }

  getToken(): string {
    return this.token;
  }

  getUserId(): number {
    return this.userId;
  }

  getValidUntil(): Date {
    return this.validUntil;
  }

  getPermissions(): any[] {
    return this.permissions;
  }

  getRaw(): any {
    return {
      username: this.username,
      token: this.token,
      userId: this.userId,
      validUntil: this.validUntil.toJSON(),
      permissions: this.permissions,
      data: this.data
    };
  }
}
