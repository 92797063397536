import {LicenseGenerator, Signer, Converter, Nonce} from '@ngmedax/common-license';

const licenseGeneratorFactory = (
) => {
  const converter = new Converter();
  const signer = new Signer(converter);
  const nonce = new Nonce();
  return new LicenseGenerator(signer, converter, nonce);
};

/**
 * Provider for keyPair generator
 */
export const LicenseGeneratorProvider = {
  provide: LicenseGenerator,
  useFactory: licenseGeneratorFactory,
  deps: []
};
