import {Injectable} from '@angular/core';
import * as yaml from 'js-yaml';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';
import {configKeys} from '../translation.config-keys';

declare const escape: Function;

@Injectable()
export class LocaleDownloaderService {
  /**
   * Injects dependencies
   *
   * @param {ConfigService} config
   * @param {HttpClient} http
   */
  public constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
  }

  /**
   * Downloads locale and return is.
   *
   * @param {string} locale
   * @returns {Promise<any>}
   */
  public async download(locale: string): Promise<any> {
    const localeUrl = this.config.get(configKeys.TRANSLATION_LOCALES_DOWNLOAD_URI)
      .replace(/%locale%/, locale)
      .replace(/%ts%/, Date.now());

    try {
      const buffer = new Uint8Array(await this.binaryGet(localeUrl));
      // @ts-ignore
      const data = decodeURIComponent(escape(String.fromCharCode.apply(null, buffer)));
      return yaml.load(data);
    } catch (error) {
      // @ts-ignore
      console.error(`Failed to load locale: ${localeUrl}. error was: ${error.message}`);
    }
  }

  /**
   * Downloads file via url and returns it as array buffer
   *
   * @param {string} url
   * @returns {ArrayBuffer}
   */
  private async binaryGet(url: string): Promise<ArrayBuffer> {
    const options = {responseType: 'arraybuffer'};

    const response = await new Promise<ArrayBuffer>((resolve, reject) => {
      this.http
        .request('GET', url, <any>options)
        .subscribe(
          response => resolve(response),
          error => reject(error)
        );
    });

    return response;
  }
}
