import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Angular 13 can not redirect with query params, so we have to use a guard for this.
 * It will add the query params to the router, so we get them for the target!
 */
@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    const queryParams = route.queryParams;
    const redirectTo = route.data && route.data.redirectTo || '/module/home';

    if (Object.keys(queryParams).length > 0) {
      return this.router.createUrlTree([redirectTo], { queryParams });
    }

    this.router.navigateByUrl(redirectTo).catch(error => console.error(error));
    return false;
  }
}
