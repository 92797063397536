<div class="user-crud">
  <h2 class="mb-4">{{_(KEYS.CRUD.USER)}}</h2>


  <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{_(KEYS.CRUD.USER_DATA)}}</a>
      <ng-template ngbNavContent>
        <br>
        <form [formGroup]="userForm" class="user-data">
          <input type="hidden" name="userId" formControlName="userId">

          <ng-container *ngIf="!userForm.get('userId').value">
            <div class="form-group group-email">
              <label for="email">{{_(KEYS.CRUD.USERNAME)}}</label>
              <input
                class="form-control"
                id="email"
                placeholder="{{_(KEYS.CRUD.ENTER_USERNAME)}}"
                formControlName="email"
                name="email">
            </div>

            <div class="form-group group-email-validation">
              <div
                class="invalid-feedback display"
                *ngIf="email.dirty && email.errors?.email">
                {{_(KEYS.CRUD.ENTER_EMAIL)}}
              </div>
            </div>

            <div class="form-group group-password">
              <label for="password">{{_(KEYS.CRUD.PASSWORD)}}</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="{{_(KEYS.CRUD.ENTER_PASSWORD)}}"
                formControlName="password"
                name="password">
            </div>

            <div class="form-group group-password-repeat">
              <label for="passwordRepeat">{{_(KEYS.CRUD.REPEAT_PASSWORD)}}</label>
              <input
                type="password"
                class="form-control"
                id="passwordRepeat"
                placeholder="{{_(KEYS.CRUD.ENTER_PASSWORD)}}"
                formControlName="passwordRepeat"
                name="passwordRepeat">
            </div>
            <div class="form-group group-password-validation">
              <div
                class="invalid-feedback display"
                *ngIf="!passwordRepeat.errors?.passwordsMatch && password.errors?.passwordPolicy">
                {{_(passwordPolicy?.hint)}}
              </div>
              <div
                class="invalid-feedback display"
                *ngIf="passwordRepeat.errors?.passwordsMatch">
                {{_(KEYS.CRUD.ENTERED_PASSWORDS_NO_MATCH)}}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="userForm.get('userId').value">
            <div class="form-group group-email">
              <label for="email">{{_(KEYS.CRUD.USERNAME)}}</label>
              <input
                class="form-control"
                name="email"
                [value]="userForm.get('email').value" readonly>
            </div>
            <div class="group-password"></div>
            <div class="group-password-repeat"></div>
            <div class="form-group group-password-validation"></div>
          </ng-container>

          <div class="group-hr1"><hr></div>

          <div class="form-group group-gender">
            <label for="gender">{{_(KEYS.CRUD.SALUTATION)}}</label>
            <select class="form-control" id="gender" name="gender" formControlName="gender">
              <option value="male">{{_(KEYS.CRUD.SALUTATION_MR)}}</option>
              <option value="female">{{_(KEYS.CRUD.SALUTATION_MS)}}</option>
            </select>
          </div>

          <div class="form-group group-title">
            <label for="title">{{_(KEYS.CRUD.TITLE)}}</label>
            <input
              class="form-control" id="title"
              placeholder="{{_(KEYS.CRUD.EG_PROF_DR)}}"
              formControlName="title"
              name="title">
          </div>

          <div class="form-group group-first-name">
            <label for="firstName">{{_(KEYS.CRUD.FIRSTNAME)}}</label>
            <input
              class="form-control" id="firstName"
              placeholder="{{_(KEYS.CRUD.ENTER_FIRSTNAME)}}"
              formControlName="firstName"
              name="firstName">
          </div>

          <div class="form-group group-last-name">
            <label for="lastName">{{_(KEYS.CRUD.LASTNAME)}}</label>
            <input
              class="form-control" id="lastName"
              placeholder="{{_(KEYS.CRUD.ENTER_LASTNAME)}}"
              formControlName="lastName"
              name="lastName">
          </div>

          <div class="group-hr2"><hr></div>

          <div class="form-group group-street">
            <label for="street">{{_(KEYS.CRUD.STREET)}}</label>
            <input
              class="form-control" id="street"
              placeholder="{{_(KEYS.CRUD.ENTER_STREET)}}"
              formControlName="street"
              name="street">
          </div>

          <div class="form-group group-zip">
            <label for="zip">{{_(KEYS.CRUD.ZIP_CODE)}}</label>
            <input
              class="form-control" id="zip"
              placeholder="{{_(KEYS.CRUD.ENTER_ZIP_CODE)}}"
              formControlName="zip"
              name="zip">
          </div>

          <div class="form-group group-city">
            <label for="city">{{_(KEYS.CRUD.CITY)}}</label>
            <input
              class="form-control" id="city"
              placeholder="{{_(KEYS.CRUD.ENTER_CITY)}}"
              formControlName="city"
              name="city">
          </div>

          <div class="group-hr3"><hr></div>

          <div class="group-control">
            <button class="btn btn-primary" [disabled]="!userForm.valid" (click)="onSubmit()">
              <i class="fa fa-save"></i>
              {{_(KEYS.CRUD.SAVE)}}
            </button>
          </div>
        </form>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" [disabled]="!user || !user.userId">
      <a ngbNavLink>{{_(KEYS.CRUD.CHANGE_PASSWORD)}}</a>
      <ng-template ngbNavContent>
        <br>
        <app-user-change-password
          [user]="user"
          [passwordPolicy]="passwordPolicy"
          [requireCurrentPassword]="requireCurrentPassword"
          (onPasswordChanged)="this.user = $event;">
        </app-user-change-password>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" [disabled]="!user || !user.userId || !previewMode || !hasFilterGroupSupport">
      <a ngbNavLink>{{_(KEYS.CRUD.FILTER_GROUPS)}}</a>
      <ng-template ngbNavContent>
        <br>
        <h2>
          {{_(KEYS.CRUD.FILTER_GROUPS)}}
          <small class="text-muted">{{user.email}}</small>
        </h2>
        <br>
        <app-user-filter-group
          [user]="user">
        </app-user-filter-group>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" [disabled]="!user || !user.userId">
      <a ngbNavLink>{{_(KEYS.CRUD.PERMISSIONS)}}</a>
      <ng-template ngbNavContent>
        <br>
        <h2>
          {{_(KEYS.CRUD.USER_PERMISSIONS)}}
          <small class="text-muted">{{user.email}}</small>
        </h2>
        <br>
        <app-user-permission [user]="user"></app-user-permission>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
