import {Injectable} from '@angular/core';
import * as nacl from 'tweetnacl';
import {Converter, KeyPair} from '@ngmedax/common-license';


@Injectable()
export class LicenseCipher {
  private nonce = '2k0xk45g840ksj523kcdk5s40';

  public constructor(
    private converter: Converter) {
  }

  public encrypt(base64License, keyPair: KeyPair) {
    const bufferMessage = this.converter.base64ToBuffer(base64License);
    const bufferNonce = this.converter.toUint8Array(this.nonce).slice(0, 24);
    const encrypted = nacl.secretbox(bufferMessage, bufferNonce, keyPair.getPrivateKey().slice(0, 32));
    return this.converter.toBase64(encrypted);
  }

  public decrypt(encryptedLicense, keyPair: KeyPair) {
    const buffer = this.converter.base64ToBuffer(encryptedLicense);
    const bufferNonce = this.converter.toUint8Array(this.nonce).slice(0, 24);
    const decrypted = nacl.secretbox.open(buffer, bufferNonce, keyPair.getPrivateKey().slice(0, 32))
    return this.converter.toBase64(decrypted);
  }
}
