export const TRANSLATE_COMPONENT_TEMPLATE =
  '<span class="translated" #translation>\n' +
  '  <ng-content></ng-content>\n' +
  '</span>';

export const DATE_FORMAT_YMD_HMS = 'DD.MM.YYYY HH:mm:ss';
export const DATE_FORMAT_YMD_HM = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT_YMD = 'DD.MM.YYYY';
export const DATE_FORMAT_YM = 'MM.YYYY';
export const DATE_FORMAT_Y = 'YYYY';
export const DATE_FORMAT_M = 'MM';

