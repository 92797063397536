import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datex'
})

/**
 * Alternative date pipe. The one angular4 provides does not work in ie11
 */
export class DatexPipe implements PipeTransform {
  /**
   * Converts date string from mysql date/time to given format
   *
   * @param value
   * @param {string} format
   * @returns {string}
   */
  transform(value: any, format: string = ''): string {
    const outputFormat = 'YYYY-MM-DD HH:mm:ss';

    // not valid? just return!
    if (!moment(value, outputFormat).isValid()) {
      return value;
    }

    // parse the passed value
    const momentDate = moment(new Date(value), outputFormat);

    // format date and return it
    return momentDate.format(format);
  }
}
