import {ConfigService} from './config.service';

/**
 * Loader that initializes the config service by loading the json config file
 *
 * @param {ConfigService} configService
 * @param {string} configFile
 * @returns {Promise}
 */
export function ConfigServiceLoader(configService: ConfigService, configFile = '/assets/config/config.json') {
  return () => configService.load(configFile);
}
