import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigServiceLoader} from './config.service.loader';
import {ConfigService} from './config.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    ConfigService,
    {provide: 'BASE_CONFIG', useValue: {}},
    {provide: 'MODIFIER_FN', useValue: null},
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigServiceLoader,
      deps: [ConfigService],
      multi: true
    }
  ]
})
export class ConfigModule {
  public static forConfig(config: any, modifier: (config: any) => void): ModuleWithProviders<any> {

    return {
      ngModule: ConfigModule,
      providers: [
        {provide: 'BASE_CONFIG', useValue: config},
        {provide: 'MODIFIER_FN', useValue: modifier}
      ]
    };
  }
}
