import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class LoginEventService {
  private afterLogin: EventEmitter<{tokenLogin?: boolean}> = new EventEmitter<{tokenLogin?: boolean}>();
  private afterLogout: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Returns event emitter for "on after login" event
   */
  public onAfterLogin(): EventEmitter<{tokenLogin?: boolean}> {
    return this.afterLogin;
  }

  /**
   * Returns event emitter for "on after login" event
   */
  public onAfterLogout(): EventEmitter<void> {
    return this.afterLogout;
  }
}
