import {ConfigService} from '@ngmedax/config';
import {ThemeService} from './theme.service';


export function ThemeLoader(
  configService: ConfigService,
  themeService: ThemeService
) {
  return () => {
    return new Promise<void>((resolve) => {
      let times = 0;

      /**
       * Using an interval is no longer needed. Config service should be
       * loaded at this point! But anyway, it doesn't hurt to keep this
       * functionality. It will make sure that themes are always loaded
       * even if the ng di provider is not configured properly!
       */
      const interval = setInterval(() => {
        // break after 10 seconds
        if (times > 100) {
          console.warn('theme module: was unable to get loaded config');
          clearInterval(interval);
          resolve();
          return;
        }
        times++;

        if (!configService.isLoaded()) {
          return;
        }

        // get theme css file
        const themeCss = configService.get('css');
        clearInterval(interval);

        // get theme logo
        const themeLogo = configService.get('logo');

        // get theme title
        const themeTitle = configService.get('title');

        // get theme favicon
        const themeFavicon = configService.get('favicon');

        // set theme logo
        themeService.setLogo(themeLogo);

        // set theme title
        themeService.setTitle(themeTitle);

        // set theme favicon
        themeService.setFavicon(themeFavicon);

        // no theme found
        if (themeCss === null) {
          resolve();
          return;
        }

        // load css theme
        themeService.load(themeCss)
          .then(() => resolve())
          .catch(() => resolve());

      }, 100);
    });
  };
}
