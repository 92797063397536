import { Component, OnInit } from '@angular/core';
import {LayoutService} from './layout.service';


@Component({
  selector: 'app-home',
  templateUrl: './layout-home.component.html',
  styles: []
})
export class LayoutHomeComponent implements OnInit {
  public homeComponent: any = null;

  /**
   * Injects dependencies
   */
  constructor(
    private layoutService: LayoutService,
  ) {
  }

  ngOnInit() {
    this.homeComponent = this.layoutService.getHomeComponent();
    this.layoutService.subscribeToHomeComponentChanged(cmp => this.homeComponent = cmp);
  }
}
