import {License} from '../../../../types';
import {Converter} from '../converter';
import {KeyPair} from '../key-pair';
import {Signer} from '../signer';


/**
 * License decoder
 */
export class LicenseDecoder {

  /**
   * Initializes new license decoder
   *
   * @param {Converter} converter
   * @param {Signer} signer
   */
  public constructor(
    private converter: Converter,
    private signer: Signer
  ) {
  }

  /**
   * Decodes license
   *
   * @param {Buffer | Uint8Array | string} license
   * @param {KeyPair} keyPair
   * @returns {License}
   */
  public decode(license: Buffer | Uint8Array | string, keyPair: KeyPair): License {
    const data = this.converter.toUint8Array(license);
    const json = this.signer.open(data, keyPair.getPublicKey());
    return JSON.parse(json);
  }
}
