import {Injectable} from '@angular/core';
import {Question} from '../../structure';
import {SCHEMA_VERSION} from '../../../schema-version';
import {Questionnaire, Translation} from '@ngmedax/common-questionnaire-types';
import {QuestionnaireVariablesService} from './questionnaire-variables.service';
import * as sha from 'js-sha256';


@Injectable()
export class QuestionnaireFormatService {
  /**
   * Path hash for meta title
   */
  private metaTitlePathHash = sha.sha256('meta.title');

  /**
   * Injects dependencies
   */
  public constructor(
    private variables: QuestionnaireVariablesService
  ) {
  }

  /**
   * Generates and returns a new questionnaire
   *
   * @param tenantId
   * @param userId
   * @returns {Questionnaire}
   */
  public generateQuestionnaire(tenantId: number, userName: string) {
    const variables = {gdt: {'6304': {[this.metaTitlePathHash]: {keepMe: true} }}};
    const variablesMapping = [];

    const questionnaire: Questionnaire = {
      id: this.generateUUID(),
      status: 'draft',
      revision: 1,
      softwareVersion: SCHEMA_VERSION,
      tenantId: tenantId,
      meta: {
        author: userName,
        title: {'de_DE': ''},
        description: {'de_DE': ''},
        type: 'default',
        asset: {},
        options: {pdf: {}, gdt: {addQuestionnaireTitle: true}, fill: {skipList: true}, variables, variablesMapping},
        tags: []
      },
      questions: []
    };

    return questionnaire;
  }

  /**
   * Packs the questionnaire by removing all hashes and shortening the matrix questions
   *
   * @param {Questionnaire} questionnaire
   * @returns {Questionnaire}
   */
  public pack(questionnaire: Questionnaire): Questionnaire {
    const questionnaireClone = this.clone(questionnaire);

    for (const container of questionnaireClone.questions) {
      // remove hashes
      this.removeHashes(container);

      // skip if not a matrix question
      if (container.format !== Questionnaire.Container.Format.MATRIX) {
        continue;
      }

      // init elements array if it is not set
      if (!Array.isArray(container.elements[0].elements)) {
        container.elements[0].elements = [];
      }

      // skip if already packed
      if (container.elements[0].title && Array.isArray(container.elements[0].title)) {
        continue;
      }

      const cols = this.clone(container.elements[0].elements);
      const rows = this.clone(container.elements);
      const rowTitles = [];
      const rowAppendices = [];

      for (const col of cols) {
        delete(col.pathHash);
        delete(col.parentHash);
      }

      for (const row of rows) {
        const rowTitle: Translation = row.title;
        rowTitle.id = row.id;
        rowTitles.push(rowTitle);

        if (row.appendix) {
          const rowAppendix: Translation = row.appendix;
          rowAppendix.id = row.id;
          rowAppendices.push(rowAppendix);
        }
      }

      const element: any = {
        id: container.packedId,
        omitContainer: true,
        title: rowTitles,
        elements: cols
      };

      rowAppendices.length && (element.appendix = rowAppendices);
      container.elements = [element];
      delete(container.packedId);
    }

    return questionnaireClone;
  }

  /**
   * Unpacks the questionnaire by widening the matrix questions
   *
   * @param {Questionnaire} questionnaire
   * @returns {Questionnaire}
   */
  public unpack(questionnaire: Questionnaire): Questionnaire {
    for (const container of questionnaire.questions) {
      // skip if not a matrix question
      if (container.format !== Questionnaire.Container.Format.MATRIX) {
        continue;
      }

      // skip if title is not an array
      if (!container.elements || !container.elements[0].title || !Array.isArray(container.elements[0].title)) {
        continue;
      }

      // init elements array if it is not set
      if (!Array.isArray(container.elements[0].elements)) {
        container.elements[0].elements = [];
      }

      const titleRows = this.clone(container.elements[0].title);
      const titleAppendix = container.elements[0].appendix ? this.clone(container.elements[0].appendix) : [];
      const cols = this.clone(container.elements[0].elements);

      container.packedId = container.elements[0].id;
      container.elements = [];

      for (const [index, row] of titleRows.entries()) {
        const title = this.clone(row);
        delete(title.id);
        delete(title.pathHash);
        delete(title.parentHash);

        const rowContainer: Questionnaire.Container = {
          id: (<Translation>row).id,
          path: null,
          pathHash: null,
          parentHash: null,
          title: title,
          elements: []
        };

        if (titleAppendix.length && titleAppendix[index]) {
          const appendix = titleAppendix[index];
          delete(appendix.id);
          delete(appendix.pathHash);
          delete(appendix.parentHash);
          rowContainer.appendix = appendix;
        }

        for (const col of cols) {
          const colContainer = this.clone(col);
          colContainer.path = null;
          colContainer.pathHash = null;
          colContainer.parentHash = null;
          rowContainer.elements.push(colContainer);
        }

        container.elements.push(rowContainer);
      }
    }
    return questionnaire;
  }

  /**
   * Flattens the questionnaire questions. Searches for group containers (pages).
   * If it finds one, it will replace it with the group container elements (containers)
   * followed by the group element itself.
   *
   * This way we get a flat questions array instead of a nested one. We need
   * to do this for now, as the current questionnaire editor does not support
   * groups. Instead we will use a page break element, that will emulate the
   * group behaviour.
   *
   * E.g:
   * // before:
   * {
   *   questions: [
   *     {displayType: 'group', elements: [
   *       {title: {'de_DE': 'Question 1'}, ...}
   *     ]}
   *   ]
   * ]}
   *
   * // after
   * {
   *   questions: [
   *     {title: {de_DE: 'Question 1'}, ...},
   *     {displayType: 'group', ...}
   *   ]
   * }
   *
   * When a questionnaire is saved by the editor, the original grouped format
   * must be restored!
   *
   * @param {Questionnaire} questionnaire
   * @returns {Questionnaire}
   */
  public flattenGroups(questionnaire: Questionnaire): Questionnaire {
    const questions: Questionnaire.Container[] = [];

    // early bailout. no questions!
    if (!questionnaire.questions) {
      return questionnaire;
    }

    for (const container of questionnaire.questions) {
      // group found? lets flatten it
      if (container.displayType === Questionnaire.Container.DisplayType.GROUP) {
        // adds all elements to the question array
        questions.push(...container.elements);
        // we no longer need the elements in the group
        delete(container.elements);
      }

      // now we convert the group to a page break and add it to the questions
      container.format = Questionnaire.Container.Format.PAGE_BREAK;
      container.omitContainer = true;
      questions.push(container);
    }

    questionnaire.questions = questions;
    return questionnaire;
  }

  /**
   * Reverts the changes from method "flattenGroups". Converts "pageBreak" elements back to
   * "group" elements and adds all elements previous to the "pageBreak" element as children
   * of the "group" element.
   *
   * @param {Questionnaire} questionnaire
   * @returns {Questionnaire}
   */
  public nestGroups(questionnaire: Questionnaire): Questionnaire {
    // clone given questionnaire
    const questionnaireClone = this.clone(questionnaire);

    let containerStorage: Questionnaire.Container[] = [];
    const questions: Questionnaire.Container[] = [];

    // iterate all questions
    for (const container of questionnaireClone.questions) {
      if (container.format && container.format === Questionnaire.Container.Format.PAGE_BREAK) {
        container.displayType = Questionnaire.Container.DisplayType.GROUP;

        // the "group" element does not need a format
        delete(container.format);

        // add all previous elements as children to the current "group" element
        container.elements = this.clone(containerStorage);

        containerStorage = [];
        questions.push(container);
      } else {
        containerStorage.push(container);
      }
    }

    // container storage not empty = we have elements without a page
    if (containerStorage.length) {
      const container = this.clone(Question.Structure.GROUP);
      this.removeHashes(container);

      container.id = this.generateUUID();

      // add all elements as children to the "group" element
      container.elements = containerStorage;
      questions.push(container);
    }

    // replace questions
    questionnaireClone.questions = questions;
    return questionnaireClone;
  }

  /**
   * Transforms questionnaire to newest format
   *
   * @param questionnaire
   */
  public transform(questionnaire: Questionnaire) {
    // hotfix for old questionnaire format with broken options/asset type
    questionnaire.meta && Array.isArray(questionnaire.meta.options) && (questionnaire.meta.options = {});
    questionnaire.meta && Array.isArray(questionnaire.meta.asset) && (questionnaire.meta.asset = {});
    !questionnaire.meta.options && (questionnaire.meta.options = {});
    !questionnaire.meta.options.pdf && (questionnaire.meta.options.pdf = {});
    !questionnaire.meta.options.pdf.forms && (questionnaire.meta.options.pdf.forms = []);
    !questionnaire.meta.options.fill && (questionnaire.meta.options.fill = {});
    !questionnaire.meta.options.gdt && (questionnaire.meta.options.gdt = {addQuestionnaireTitle: true});
    !questionnaire.meta.options.variablesMapping && (questionnaire.meta.options.variablesMapping = []);
    !questionnaire.meta.options.variables && (questionnaire.meta.options.variables = {});
    !questionnaire.meta.options.variables.gdt && (questionnaire.meta.options.variables.gdt = {});

    // adds gdt var mapping for meta title
    const flipped = this.variables.flipVariables(questionnaire.meta.options.variables.gdt);

    if (!flipped[this.metaTitlePathHash]) {
      !questionnaire.meta.options.variables.gdt['6304'] && (questionnaire.meta.options.variables.gdt['6304'] = {[this.metaTitlePathHash]: {keepMe: true}});
      !questionnaire.meta.options.variables.gdt['6304'][this.metaTitlePathHash]
      && (questionnaire.meta.options.variables.gdt['6304'][this.metaTitlePathHash] = {keepMe: true});
    }
    // gdt var mapping: end

    // init meta.asset object
    (!questionnaire.meta.asset) && (questionnaire.meta.asset = {});

    // transform meta.image to meta.asset.image
    if (questionnaire.meta.image && (!questionnaire.meta.asset || !questionnaire.meta.asset.image)) {
      questionnaire.meta.asset.image = {
        title: {'de_DE': ''},
        filename: questionnaire.meta.image
      };

      delete(questionnaire.meta.image);
    }
  }

  /**
   * Generates a uuid
   * @returns {string}
   */
  public generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Packs matrix questions, converts flat questionnaire into nested one
   * and wraps into payload with defined $schema
   *
   * @param {Questionnaire} questionnaire
   * @param {boolean} unpacked
   * @returns {{$schema: string, questionnaire: Questionnaire}}
   */
  public getPayload(questionnaire: Questionnaire, unpacked = false): {$schema: string, questionnaire: Questionnaire} {
    this.cleanupTitles(questionnaire);

    const flatQuestionnaire = (unpacked) ?
      this.nestGroups(this.clone(questionnaire)) :
      this.nestGroups(this.pack(this.clone(questionnaire)));

    return {
      $schema: 'questionnaire-schema.json',
      questionnaire: flatQuestionnaire,
    };
  }

  /**
   * Removes html from matrix question titles
   *
   * @param {Questionnaire} questionnaire
   */
  private cleanupTitles(questionnaire: Questionnaire) {
    const strip = (el: Questionnaire.Container, skipMainTitle = false) => {
      if (!el.title) {
        return;
      }

      if (!skipMainTitle) {
        const titles = Array.isArray(el.title) ? el.title : [el.title];
        el.appendix && titles.push(el.appendix);

        titles.forEach(title => Object.keys(title)
          .forEach(key => title[key] = new DOMParser().parseFromString(title[key], 'text/html').body.textContent));
      }

      el.elements && el.elements.forEach(el => strip(el));
    }

    const shouldStrip = (el: Questionnaire.Container) => el.format === 'matrix' || !!el.format.match(/Choice/);
    questionnaire.questions.filter(q => shouldStrip(q)).forEach(question => strip(question, true));
  }

  /**
   * Clones the given object
   *
   * @param {any} obj
   * @returns {any}
   */
  public clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Removes hashes from container
   * @param {Questionnaire.Container} container
   */
  private removeHashes(container: Questionnaire.Container) {
    delete(container.path);
    delete(container.pathHash);
    delete(container.parentHash);

    if (container.elements) {
      for (const child of container.elements) {
        this.removeHashes(child);
      }
    }
  }
}
