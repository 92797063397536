<form [formGroup]="passwordChangeForm" class="user-new-password">
  <div class="form-group group-old-password" [ngClass]="{'hidden': oldPassword.disabled}">
    <label for="oldPassword">{{_(KEYS.CRUD.CURRENT_PASSWORD)}}</label>
    <input
      type="password"
      class="form-control"
      id="oldPassword"
      placeholder="{{_(KEYS.CRUD.ENTER_CURRENT_PASSWORD)}}"
      formControlName="oldPassword"
      name="oldPassword"
      (keyup)="onResetOldPasswordCheckTimeout()">
    <div
      class="invalid-feedback display"
      *ngIf="oldPassword.errors?.oldPassword">
      {{_(KEYS.CRUD.INVALID_PASSWORD)}}
    </div>
  </div>

  <div class="form-group group-new-password">
    <label for="newPassword">{{_(KEYS.CRUD.NEW_PASSWORD)}}</label>
    <input
      type="password"
      class="form-control"
      id="newPassword"
      placeholder="{{_(KEYS.CRUD.ENTER_NEW_PASSWORD)}}"
      formControlName="newPassword"
      name="newPassword">
    <div
      class="invalid-feedback display"
      *ngIf="newPassword.errors?.passwordPolicy">
      {{_(passwordPolicy?.hint)}}
    </div>
  </div>

  <div class="form-group group-new-password-repeat">
    <label for="newPasswordRepeat">{{_(KEYS.CRUD.REPEAT_NEW_PASSWORD)}}</label>
    <input
      type="password"
      class="form-control"
      id="newPasswordRepeat"
      placeholder="{{_(KEYS.CRUD.ENTER_NEW_PASSWORD)}}"
      formControlName="newPasswordRepeat"
      name="newPasswordRepeat">
    <div
      class="invalid-feedback display"
      *ngIf="newPasswordRepeat.errors?.passwordsMatch">
      {{_(KEYS.CRUD.ENTERED_PASSWORDS_NO_MATCH)}}
    </div>
  </div>

  <div class="group-new-control">
    <button class="btn btn-primary" [disabled]="!passwordChangeForm.valid" (click)="onPasswordChange()">
      <i class="fa fa-save"></i>
      {{_(KEYS.CRUD.CHANGE_PASSWORD)}}
    </button>
  </div>
</form>
