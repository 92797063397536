import {Component, ElementRef, OnInit, Optional, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {LayoutService} from '@ngmedax/layout';
import {ErrorService} from '@ngmedax/error';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {LoginService} from '../../service/login.service';
import {LoginConfigService} from '../../service/login.config.service';
import {TRANSLATION_LOGIN_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface LoginComponent extends Translatable {}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [
    {provide: 'TRANSLATION_SCOPE', useValue: TRANSLATION_LOGIN_SCOPE},
  ]
})
@Translatable({scope: TRANSLATION_LOGIN_SCOPE, keys: KEYS})
export class LoginComponent implements OnInit {
  /**
   * Pattern to check if a email address is valid
   * @type {RegExp}
   */
  private emailPattern = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;

  /**
   * Login overlay element ref
   * @type {ElementRef}
   */
  @ViewChild('loginOverlay') loginOverlay: ElementRef;

  /**
   * Login card element ref
   * @type {ElementRef}
   */
  @ViewChild('loginCard') loginCard: ElementRef;

  /**
   * Whenever or not to show the white login overlay
   * @type {boolean}
   */
  public showLoginOverlay = true;

  /**
   * Flag for the form to determine if we are loading or not
   * @type {boolean}
   */
  public loading = false;

  /**
   * Tenant name
   * @type {string}
   */
  public tenantName = '';

  /**
   * Login error message
   * @type {string}
   */
  public errorMsg: string;

  /**
   * The reactive login form
   * @type {FormGroup}
   */
  public loginForm: FormGroup;

  /**
   * Is the locale module available?
   * @type {boolean}
   */
  public readonly hasLocaleSupport: boolean = false;

  /**
   * Injects some objects
   *
   * @param {FormBuilder} formBuilder
   * @param {Renderer2} renderer
   * @param {Router} router
   * @param {LoginService} loginService
   */
  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private router: Router,
    private errorService: ErrorService,
    private loginService: LoginService,
    private layoutService: LayoutService,
    private loginConfigService: LoginConfigService,
    @Optional() private translationService: TranslationService
  ) {
    this.hasLocaleSupport = !!this.translationService;
  }

  /**
   * Removes given css classes from given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private removeClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el.nativeElement, cls);
    }
  }

  /**
   * Adds given css classes to given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private addClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.addClass(el.nativeElement, cls);
    }
  }

  /**
   * Switches given element ref from previous classes to given ones
   *
   * @param {ElementRef} el
   * @param classes
   */
  private switchClasses(el: ElementRef, classes) {
    this.removeClasses(el, ['flipInX', 'shake', 'pulse', 'infinite']);
    this.addClasses(el, classes);
  }

  private showError() {
    const uri = 'login-api-url-not-found';
    const title = KEYS.LOGIN.LOGIN;
    const message = KEYS.LOGIN.ERROR_UMS_API_URI_UNCONFIGURED;
    const exampleJson = {
      'apis': {
        'ums': {
          'uri': 'http://ums.domain.tld',
          'resources': {
            'login': '/auth/login'
          }
        }
      }
    };

    this.errorService
      .addErrorMessage(uri, title, message, exampleJson)
      .navigateToError(uri);
  }

  /**
   * Inits the login form
   */
  ngOnInit() {
    // get login url via login config service
    const loginApiUrl = this.loginConfigService.getUmsLoginUrl();

    if (!loginApiUrl) {
      this.showError();
    }

    // set current tenant name
    this.tenantName = this.loginConfigService.getTenantName() || '';

    // hide white login overlay when url ends with /out
    if (this.router.url.match(/\/out$/)) {
      this.showLoginOverlay = false;
    }

    // shortcut for form builder
    const fb = this.formBuilder;

    // build login form
    this.loginForm = fb.group({
      'username': [null, [
        Validators.required,
        Validators.pattern(this.emailPattern)
      ]],
      'password': [null, Validators.required],
      'remember': [true, null]
    });
  }

  /**
   * On form submit
   */
  onSubmit() {
    // this will disable the submit button
    this.loading = true;

    // unset error message
    this.errorMsg = '';

    // pulsate login form to inform the user that we are doing something (loader replacement)
    this.switchClasses(this.loginCard, ['pulse', 'infinite']);

    // try to login the user by given username, password and remember values
    this.loginService
      .login(
        this.loginForm.value.username,
        this.loginForm.value.password,
        this.loginForm.value.remember)
      .then((userLogin) => {
        // this will re-enable the login button
        this.loading = false;

        // this will hide the white login overlay
        this.showLoginOverlay = false;

        // login successful => zoom out login form
        this.switchClasses(this.loginCard, ['zoomOut']);

        // animate the login noverlay to fade out after 400ms
        setTimeout(() => {
          this.switchClasses(this.loginOverlay, ['fadeOut']);
        }, 400);

        // set the username in the layout service (renders it in the user box)
        // TODO: somehow get user image
        this.layoutService.setUser({
          username: userLogin.getUsername()
        });

        // redirect to previous uri after 700ms (time for the animation to run)
        setTimeout(() => {
          this.router.navigateByUrl(this.loginService.getPreviousUri());
        }, 700);

      })
      .catch(error => {
        this.loading = false;

        if (error.code === 4000) {
          this.errorMsg = KEYS.LOGIN.INVALID_CREDENTIALS;
        } else if (error.code === 403) {
          this.errorMsg = KEYS.LOGIN.INSUFFICIENT_PERMISSIONS;
        } else if (error.code === 99999) {
          this.errorMsg = KEYS.LOGIN.ERROR_UMS_API_UNREACHABLE;
        } else {
          this.errorMsg = KEYS.LOGIN.ERROR_UMS_API_ANSWER + ' ' + error.message;
        }

        // shake the login form
        this.switchClasses(this.loginCard, ['shake']);
      });
  }

  /**
   * Getter for the username form element
   * @returns {FormControl}
   */
  get username() {
    return this.loginForm.get(['username']);
  }

  /**
   * Getter for the password form element
   * @returns {FormControl}
   */
  get password() {
    return this.loginForm.get(['password']);
  }
}
