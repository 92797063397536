import {Component, OnInit, Optional} from '@angular/core';
import {Route, Router} from '@angular/router';

import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LoginEventService, LoginService} from '@ngmedax/login';
import {PermissionService} from '@ngmedax/permission';
import {ValueService} from '@ngmedax/value';
import {TRANSLATION_HOME_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {ConfigService} from '@ngmedax/config';


// hack to inject decorator declarations. must occur before class declaration!
export interface HomeComponent extends Translatable {}

@Component({
  selector: 'app-qa-home',
  styleUrls: ['./home.component.css'],
  templateUrl: './home.component.html'
})
@Translatable({scope: TRANSLATION_HOME_SCOPE, keys: KEYS})
export class HomeComponent implements OnInit {
  /**
   * User name of logged in user
   */
  public userName: string;

  /**
   * Locale
   */
  public locale = 'de_DE';

  /**
   * Lang
   */
  public lang = 'de';

  /**
   * Download links
   */
  public downloadLinks: {
    userGuide: {[locale: string]: string}
    allocatorApp: {windows?: string, mac?: string, linux?: string};
  };

  /**
   * List of all routes
   */
  private routeList: {[url: string]: Route} = {};

  /**
   * Injects dependencies
   */
  constructor(
    @Optional() private loginService: LoginService,
    @Optional() private loginEvents: LoginEventService,
    @Optional() private config: ConfigService,
    @Optional() private permissionService: PermissionService,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService,
    private value: ValueService,
    private router: Router
  ) {
    for (const routeConfig of router.config) {
      this.routeList[routeConfig.path] = routeConfig;
    }
    
    this.downloadLinks = this.config.get('downloadLinks') || {};
    this.locale = this.translationService.getLocale();
    this.lang = this.locale.replace(/_.*$/, '');

    this.translationEvents.onLocaleChanged().subscribe(() => {
      this.locale = this.translationService.getLocale();
      this.lang = this.locale.replace(/_.*$/, '');
      this.setUserName();
    });
  }

  ngOnInit() {
    this.setUserName();
    this.loginEvents && this.loginEvents.onAfterLogin().subscribe(() => this.setUserName());
  }

  /**
   * Returns true if logged in user has permission to open given uri
   * @param uriPath
   */
  public canOpen(uriPath: string): boolean {
    if (!this.permissionService) {
      return true;
    }

    const routeData = this.getRouteData(uriPath) || {data: {}};
    const result = this.permissionService.isAllowedByRouteData(routeData.data);
    return result.allowed;
  }

  /**
   * Builds username by gender, first name and last name of logged in user and sets the "userName" member
   */
  private setUserName() {
    if (!this.loginService) {
      return;
    }

    const data = this.value.get(this.loginService.getUser(), ['data']);
    const firstName = this.value.get(data, ['firstName']);
    const lastName = this.value.get(data, ['lastName']);
    const gender = this.value.get(data, ['gender']) || '';
    const salutation = (gender === 'male') ? KEYS.HOME.SALUTATION_MR : KEYS.HOME.SALUTATION_MS;
    this.userName = `${this._(salutation)} ${firstName} ${lastName}`;
  }

  /**
   * Finds and returns route data by given uri. Returns empty object if no route data found
   *
   * @returns {any}
   */
  private getRouteData(uri: string) {
    uri = uri.replace(/^\//, '');
    const route = this.routeList[uri];
    return (route && route.data) ? route : {};
  }
}
