import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistryService} from './registry.service';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [],
  providers: [
    RegistryService
  ]
})
export class RegistryModule {
}
