import {Types} from '../../types';
import {Interfaces} from '../../interfaces';

export class CommonTranslationService {

  /**
   * Default locale map
   * @type {{[locale: string]: Locale}}
   */
  private map: {[locale: string]: Types.Locale} = {
    'de_DE': {
      meta: {
        name: 'Deutsch',
        locale: 'de_DE'
      },
      translations: {
        default: {},
        dateFormats: {}
      }
    }
  };

  /**
   * Default locale for when no translation was found by currently set locale
   * @type {string}
   */
  private readonly DEFAULT_LOCALE = 'default';

  /**
   * Injects dependencies
   *
   * @param {Interfaces.Value} value
   */
  public constructor(protected value: Interfaces.Value) {
  }

  /**
   * Sets locales
   *
   * @returns {void}
   */
  public setLocalesMap(locales: {[locale: string]: Types.Locale}): void  {
    this.map = locales;
  }

  /**
   * Returns locales map
   */
  public getLocalesMap() {
    return this.map;
  }

  /**
   * Translates given text. Tries to translate via locales in the following order:
   * - currently set locale
   * - defined fallback locale for currently set locale (if any)
   * - default locale
   *
   * Will try to translate with defined back locale, if unable to translate with given
   * locale. Will recursively follow back locale, until defined max tries is reached.
   *
   * This will prevent loops. E.g:
   * - en_US defines en_GB as fallback
   * - en_GB defines en_US as fallback
   *
   *
   * Also replaces placeholders after! translation, when variables given and placeholders found in given text.
   * E.g:
   * - text: "Hello [[name]]"
   * - variables: {name: "World"}
   * - result: "Hello World"
   *
   * Returns given text, if unable to translate via any locale.
   *
   * @param {TranslateOptions} options Translate options or text to translate
   * @returns {string} Translations
   */
  public translate(options: Types.TranslateOptions): string {
    const text = this.value.get(options, ['text']);
    const locale = this.value.get(options, ['locale']);
    const maxTries = this.value.get(options, ['maxTries']) || 10;
    const variables = this.value.get(options, ['variables']) || null;
    let scope: string[] = (this.value.get(options, ['scope']) || ['default']);

    if (this.value.isString(scope)) {
      scope = (<string><any>scope).split('.');
    }

    const walk = (text: string, scope: string[], locale: string, numTries: number) => {
      const t = () => {
        const translation = this.value.get(this.map, [locale, 'translations', ...scope, text]);

        if (!translation && scope[scope.length-1] !== 'default') {
          const defaultedScope = [...scope];
          defaultedScope[scope.length-1] = 'default';
          return this.value.get(this.map, [locale, 'translations', ...defaultedScope, text]);
        }

        return translation;
      };

      const backLocale = this.value.get(this.map, [locale, 'meta', 'fallback']) || this.DEFAULT_LOCALE;

      if (locale === this.DEFAULT_LOCALE || numTries >= maxTries) {
        return t() || text;
      }

      return t() || walk(text, scope, backLocale, numTries + 1);
    };

    return this.render(walk(text, scope, locale, 0), variables);
  }

  /**
   * Returns true if translator has given locale
   *
   * @param {string} locale
   */
  public hasLocale(locale: string) {
    return this.value.isString(locale) && !!this.value.get(this.map, [locale]);
  }

  /**
   * Returns list of supported locales
   *
   * @returns {string[]}
   */
  public getLocales(): string[] {
    return Object.keys(this.map).filter(locale => locale !== 'default');
  }

  /**
   * Returns lang name of locale in lang of locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleName(locale: string): string {
    return this.value.get(this.map, [locale, 'meta', 'name']);
  }

  /**
   * Returns configured font for this locale (if any)
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleFont(locale: string): string {
    return this.value.get(this.map, [locale, 'meta', 'font']);
  }

  /**
   * Returns ISO 3166-1 alpha-2 or ISO-3166-2 country code by given locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleCountryCode(locale: string) {
    return this.value.isString(locale) ? locale.replace(/^[a-z]{2}_/, '') : locale;
  }

  /**
   * Returns uft8 char for country flag of given locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleFlagEmoji(locale: string): string {
    const country = locale.replace(/^[a-z]{2}_/, '');
    const configuredFlag = this.value.get(this.map, [locale, 'meta', 'flag', 'emoji']);

    if (configuredFlag) {
      return configuredFlag;
    }

    const offset = 127397;
    const A = 65;
    const Z = 90;
    const f = country.codePointAt(0);
    const s = country.codePointAt(1);

    if (this.value.isString(country) && country.length !== 2 || f > Z || f < A || s > Z || s < A) {
      return '';
    }

    return String.fromCodePoint(f + offset)
      + String.fromCodePoint(s + offset);
  }

  /**
   * Returns localized date format for given english default date format
   *
   * @param {string} locale
   * @param {string} enFormat
   * @returns {string}
   */
  public getDateFormat(locale: string, enFormat: string): string {
    return this.translate({locale, text: enFormat, scope: 'dateFormats'});
  }

  /**
   * Renders template by given variables
   *
   * @param {string} template
   * @param {key: string]: any}} variables
   * @returns {string}
   */
  private render(template: string, variables: {[key: string]: any}): string {
    if (!variables) {
      return template;
    }

    const templateVars = template.match(/\[\[.*?\]\]/g);

    templateVars && templateVars.map(templateVar => {
      templateVar = templateVar.replace(/[\[\]]/g, '')
      const regExp = new RegExp('\\[\\[' + templateVar + '\\]\\]', 'g');
      const val = this.value.get(variables, [templateVar]);
      val && (template = template.replace(regExp, val));
    });

    return template;
  }
}
