import {Component, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import {DATE_FORMAT_YMD_HMS, Translatable, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {ValueService} from '@ngmedax/value';

import {TRANSLATION_HISTORY_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {SurveyHistory} from '../../../types';
import {DateFormatService} from '../../../../translation';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SurveyGridService} from '../services/survey-grid.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface SurveyHistoryComponent extends Translatable {}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-survey-history',
  templateUrl: './survey-history.component.html',
  styleUrls: ['./survey-history.component.css'],
})
@Translatable({scope: TRANSLATION_HISTORY_SCOPE, keys: KEYS})
export class SurveyHistoryComponent implements OnInit {
  /**
   * Locale for surveys. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  /**
   * Survey history
   */
  public history: SurveyHistory[] = [];

  /**
   * Survey history entries found?
   */
  public noEntries: boolean = false;

  /**
   * Icon mapping for events
   */
  private iconMapping = {
    surveyCreated: 'fa-plus',
    surveyAssigned: 'fa-user',
    surveyExportAdded: 'fa-wpforms',
    surveySubmitted: 'fa-send-o',
    surveyDocumentSigned: 'fa-wpforms',
    surveySigned: 'fa-edit',
    surveyDone: 'fa-check',
    surveyMailSent: 'fa-envelope-o',
    surveyExportDownloaded: 'fa-download',
    surveyCanceled: 'fa-ban'
  };

  /**
   */
  public ngOnInit() {
  }

  /**
   * Injects dependencies
   */
  public constructor(
    public activeModal: NgbActiveModal,
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private surveyGridService: SurveyGridService,
    private value: ValueService,
    private layout: LayoutService
  ) {
  }

  /**
   * Load survey history
   * @param surveyId
   */
  public load(surveyId: string) {
    this.surveyGridService.loadSurveyHistory(surveyId).then(res => {
      (!res.rows || !res.rows.length) && (this.noEntries = true);
      this.history = res.rows;
    });
  }

  public getIcon(history: SurveyHistory) {
    return this.value.get(this.iconMapping, [history.event]);
  }

  public getText(history: SurveyHistory) {
    const fileName = (history: SurveyHistory) => this.value.get(history, ['data', 'fileName'])
    const questTitle = (history: SurveyHistory) => this.value.get(history, ['data', 'questionnaireTitle', this.locale]);
    const tpl = (history: SurveyHistory) => {
      return `${this._('Fragebogen')}: "${questTitle(history)}", ${this._('Datei')}: "${fileName(history)}".`
    }

    switch(history.event) {
      case 'surveyCreated':
        return this._('Befragung wurde angelegt.');
        break;
      case 'surveyAssigned':
        return this._('Befragung wurde auf das Gerät geladen.');
        break;
      case 'surveyExportAdded':
      case 'surveyDocumentSigned':
        return this._('Export Datei erzeugt.') + ` ${tpl(history)}`;
        break;
      case 'surveySubmitted':
        return this._('Befragung erfolgreich abgegeben.');
        break;
      case 'surveySigned':
        return this._('Alle Dokumente der Befragung signiert.');
        break;
      case 'surveyDone':
        return this._('Befragung abgeschlossen.');
        break;
      case 'surveyMailSent':
        return this._('Befragung wurde per Mail an Ausfüller gesendet.');
        break;
      case 'surveyExportDownloaded':
        return this._('Export Datei heruntergeladen.') + ` ${tpl(history)}`;
        break;
      case 'surveyCanceled':
        return this._('Befragung wurde abgebrochen.');
        break;
    }
  }

  /**
   * date format to use
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HMS.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };
}
