<div class="auth-token">
  <h2 class="mb-4">{{_(KEYS.AUTH_TOKEN.AUTH_TOKEN)}}</h2>

  <form [formGroup]="authTokenForm" class="user-data">
    <ng-container>
      <div class="form-group group-email">
        <label for="email">{{_(KEYS.AUTH_TOKEN.USERNAME)}}</label>
        <input
          class="form-control"
          id="email"
          placeholder="{{_(KEYS.AUTH_TOKEN.ENTER_USERNAME)}}"
          formControlName="email"
          name="email">
      </div>

      <div class="form-group group-email-validation">
        <div
          class="invalid-feedback display"
          *ngIf="email.dirty && email.errors?.email">
          {{_(KEYS.AUTH_TOKEN.ENTER_EMAIL)}}
        </div>
      </div>

      <div class="form-group group-password">
        <label for="password">{{_(KEYS.AUTH_TOKEN.PASSWORD)}}</label>
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="{{_(KEYS.AUTH_TOKEN.ENTER_PASSWORD)}}"
          formControlName="password"
          name="password">
      </div>

      <div class="form-group group-password-validation">
        <div
          class="invalid-feedback display"
          *ngIf="password.errors?.passwordPolicy">
          {{_(passwordPolicy?.hint)}}
        </div>
      </div>

      <div class="form-group group-password">
        <label for="secret">{{_(KEYS.AUTH_TOKEN.SHARED_SECRET)}}</label>
        <input
          type="password"
          class="form-control"
          id="secret"
          placeholder="{{_(KEYS.AUTH_TOKEN.ENTER_SHARED_SECRET)}}"
          formControlName="secret"
          name="secret">
      </div>

      <div class="group-new-control">
        <button class="btn btn-primary" [disabled]="!authTokenForm.valid" (click)="onSubmit()">
          <i class="fa fa-key"></i>
          {{_(KEYS.AUTH_TOKEN.CREATE_TOKEN)}}
        </button>
      </div>

      <hr>
      <br>

      <div class="token" *ngIf="authToken" [ngClass]="{red: !isValidAuthToken}">
        <i class="fa fa-key icon"></i>
        <span class="wrap">{{authToken}}</span>
      </div>

      <div class="form-group group-token-validation">
        <div
          class="invalid-feedback display"
          *ngIf="authToken && !isValidAuthToken">
          &nbsp; {{_(KEYS.AUTH_TOKEN.INVALID_AUTH_TOKEN)}}
        </div>
      </div>
    </ng-container>
  </form>
</div>
