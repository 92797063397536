import {Injectable} from '@angular/core';
import {Types as CommonTranslateTypes} from '@ngmedax/common-translation';
import {ValueService} from '@ngmedax/value';
import {ConfigService} from '@ngmedax/config';
import {TranslationEventService} from './translation-event.service';
import {BaseTranslationService} from './base-translation.service';
import {LocaleDownloaderService} from './locale-downloader.service';


/**
 * Translation service
 */
@Injectable()
export class TranslationService {
  /**
   * Default locale
   */
  private locale = 'de_DE';

  /**
   * Injects dependencies
   */
  public constructor(
    private baseTranslation: BaseTranslationService,
    private downloader: LocaleDownloaderService,
    private events: TranslationEventService,
    private config: ConfigService,
    private value: ValueService
  ) {
  }

  /**
   * Loads all available locales into properties and initializes one of the following locales:
   * - locale given via param
   * - currently selected locale
   * - default locale
   *
   * @returns {Promise<void>}
   */
  public async loadLocales(): Promise<void> {
    const index: {locales: string[]} = <any>await this.downloader.download('index');
    const map: any = {};

    for (const localeKey of (this.value.get(index, ['locales']) || [])) {
      const locale: CommonTranslateTypes.Locale = await this.downloader.download(localeKey);
      const aliases: CommonTranslateTypes.Locale.Meta.Aliases = this.value.get(locale, ['meta', 'aliases']) || [];
      const localeName: string[] = this.value.get(locale, ['meta', 'name']);

      localeName && (map[localeKey] = locale);

      for (const aliasLocale of aliases) {
        map[aliasLocale] = map[localeKey];
      }
    }

    this.baseTranslation.setLocalesMap(map);
  }

  /**
   * Activates one of the the following locales:
   * - locale given via param
   * - persisted locale
   * - default locale
   *
   * @param {string} [useLocale=null]
   */
  public async setActiveLocale(useLocale: string = null) {
    const persistedLocale = localStorage ? localStorage.getItem('locale') : null;
    this.setLocale(useLocale || persistedLocale || this.locale);
  }

  /**
   * Translates given text
   *
   * @param {CommonTranslateTypes.TranslateOptions | string} options Translate options or text to translate
   * @returns {string} Translation
   */
  public translate(options: CommonTranslateTypes.TranslateOptions | string): string {
    if (this.value.isString(options)) {
      options = {text: <string>options, locale: this.locale};
    }

    options = <CommonTranslateTypes.TranslateOptions>options;

    if (this.value.isObject(options) && !options.locale) {
      options.locale = this.locale;
    }

    return this.baseTranslation.translate(options);
  }

  /**
   * Sets locale end emits "locale changed" event
   *
   * @param locale
   */
  public setLocale(locale: string) {
    if (!this.hasLocale(locale)) {
      console.log('translation service:', 'unable to set locale. not found:', locale);
      return;
    }

    this.locale = locale;
    this.events.onLocaleChanged().emit();

    localStorage && localStorage.setItem('locale', locale);

    const font = this.value.get(this.baseTranslation.getLocalesMap(), [locale, 'meta', 'font']);
    const appEl = (<any>(document.getElementsByTagName('app-root') || [])[0]);

    font && appEl && (appEl.style = `font-family: ${font}`);
    !font && appEl && (appEl.style = '');
  }

  /**
   * Returns current locale
   *
   * @returns {string}
   */
  public getLocale() {
    return this.locale;
  }

  /**
   * Returns true if translator has given locale
   *
   * @param {string} locale
   */
  public hasLocale(locale: string) {
    return this.baseTranslation.hasLocale(locale);
  }

  /**
   * Returns list of supported locales
   *
   * @returns {string[]}
   */
  public getLocales(): string[] {
    return this.baseTranslation.getLocales();
  }

  /**
   * Returns lang name of locale in lang of locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleName(locale: string): string {
    return this.baseTranslation.getLocaleName(locale);
  }

  /**
   * Returns configured font for this locale (if any)
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleFont(locale: string): string {
    return this.baseTranslation.getLocaleFont(locale);
  }

  /**
   * Returns ISO 3166-1 alpha-2 or ISO-3166-2 country code by given locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleCountryCode(locale: string) {
    return this.baseTranslation.getLocaleCountryCode(locale);
  }

  /**
   * Returns uft8 char for country flag of given locale
   *
   * @param {string} locale
   * @returns {string}
   */
  public getLocaleFlagEmoji(locale: string): string {
    const navigatorPlatform = (this.value.get(navigator, ['platform']) || '');

    if (!navigatorPlatform.match(/^mac/i) ) {
      return;
    }

    return this.baseTranslation.getLocaleFlagEmoji(locale);
  }

  /**
   * Returns localized date format for given english default date format
   *
   * @param {string} enFormat
   * @param {string} [locale=null]
   * @returns {string}
   */
  public getDateFormat(enFormat: string, locale: string = null): string {
    return this.baseTranslation.getDateFormat(locale || this.locale, enFormat);
  }
}
