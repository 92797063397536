import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ErrorService} from './error.service';
import {ErrorComponent} from './error.component';
import {routing} from './error.routing';


@NgModule({
  imports: [
    CommonModule,
    routing
  ],
  declarations: [
    ErrorComponent
  ],
  exports: [],
  providers: [
    ErrorService
  ]
})
export class ErrorModule {
}

