import {NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

import {TenantService} from './tenant.service';
import {TenantConfigService} from './tenant.config.service';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';

import {ErrorService} from '@ngmedax/error';
import {ConfigService} from '@ngmedax/config';
import {RegistryGuard} from './guard/registry.guard';
import {LayoutService} from '@ngmedax/layout';
import {Translatable, TranslationService} from '@ngmedax/translation';


// hack to inject decorator declarations. must occur before class declaration!
export interface TenantModule extends Translatable {}

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  exports: [],
  providers: [
    TenantConfigService,
    TenantService,
    RegistryGuard
  ]
})
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class TenantModule {
  constructor(
    private router: Router,
    private registryGuard: RegistryGuard,
    private layoutService: LayoutService,
    private tenantService: TenantService,
    private configService: ConfigService,
    private errorService: ErrorService,
    @Optional() private translationService: TranslationService) {

    layoutService.registerGuard(registryGuard, 50);

    // subscribe to navigation end event
    const subscription = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        // if no tenant id was found
        if (!tenantService.getTenantId()) {
          // unsubscribe to prevent redirect loop
          subscription.unsubscribe();

          // show "tenant not found" error message
          this.showErrorMessage();
          return;
        }
      });
  }

  /**
   * Adds a "tenant not found" message to the error service and redirects to the error page.
   */
  private showErrorMessage() {
    // get domain by window object
    const domain = (window && window.location) ?
      window.location.host : '...';

    // uri for the error message
    const uri = 'tenant-not-configured';

    // title that we will see on the error page
    const title = this._(KEYS.DEFAULT.CONFIG_ERROR);

    // message that we will see on the error page
    const message = this._(KEYS.DEFAULT.ERROR_TENANT_UNCONFIGURED, {domain});

    // example config object that we will se on the error page
    const exampleObject = {
      'domains': {
        [domain]: {
          'tenantId': 12345
        }
      }
    };

    // add error message and redirect to error page
    // note: error service will only redirect when we are not already on this error page
    this.errorService
      .addErrorMessage(uri, title, message, exampleObject)
      .navigateToError(uri);
  }
}
