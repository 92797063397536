import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LayoutService} from './layout.service';

/**
 * Guard to show menu. Should run at the end of the core guard chain...
 */
@Injectable()
export class ShowMenuGuard implements CanActivate {

  /**
   * Injects the layout service
   *
   * @param {LayoutService} layoutService
   */
  constructor(private layoutService: LayoutService) {}

  /**
   * Executes all registered guards in the given order (priority)
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | boolean}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.layoutService.showMenu();
    return true;
  }
}
