import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';
import {LayoutService} from '@ngmedax/layout';

import {ThemeLoader} from './theme.loader';
import {ThemeService} from './theme.service';
import {ThemeGuard} from './theme.guard';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    ThemeGuard,
    ThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: ThemeLoader,
      deps: [ConfigService, ThemeService],
      multi: true
    }
  ]
})
export class ThemeModule {

  /**
   * Registers guard to load active theme into dom
   *
   * @param {ThemeGuard} themeGuard
   * @param {LayoutService} layoutService
   */
  public constructor(
    themeGuard: ThemeGuard,
    layoutService: LayoutService
  ) {
    layoutService.registerGuard(themeGuard, 10);
  }
}

