import {EventEmitter, Injectable} from '@angular/core';

/**
 * Service for questionnaire user events
 */
@Injectable()
export class TranslationEventService {

  private localeChanged: EventEmitter<string>
    = new EventEmitter<string>();

  private localesPersisted: EventEmitter<void>
    = new EventEmitter<void>();

  public onLocaleChanged(): EventEmitter<string> {
    return this.localeChanged;
  }

  public onLocalesPersisted(): EventEmitter<void> {
    return this.localesPersisted;
  }
}
