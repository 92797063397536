import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './error.component';

const APP_ROUTES: Routes = [
  {
    path: 'module/error/:uri',
    component: ErrorComponent,
    pathMatch: 'full'
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
