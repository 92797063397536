<app-global-style></app-global-style>
<div [ngClass]="{'login-overlay-white': showLoginOverlay}">
  <div class="login-overlay floating-image animated fadeIn" #loginOverlay>
    <div class="login-locale-select" *ngIf="hasLocaleSupport">
      <app-locale-select></app-locale-select>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center text-white mb-4"><br><br></h2>
          <div class="row">
            <div class="col-md-4 mx-auto">
              <span class="anchor" id="formLogin"></span>

              <!-- form card login -->
              <div class="card rounded-2 animated flipInX" #loginCard>
                <div class="card-header">
                  <h3 class="mb-0" *ngIf="!!tenantName">{{tenantName}}</h3>
                  <h3 class="mb-0" *ngIf="!tenantName">{{_(KEYS.LOGIN.LOGIN)}}</h3>
                </div>
                <div class="card-body">
                  <form class="form" role="form" autocomplete="off" [formGroup]="loginForm">
                    <div class="form-group">
                      <label for="username">{{_(KEYS.LOGIN.USERNAME)}}</label>

                      <div class="input-group mb-2 mb-sm-0">
                        <div class="input-group-addon">
                          <i class="fa fa-user"></i>
                        </div>
                        <input
                          type="text"
                          class="form-control form-control-lg rounded-2"
                          name="username"
                          id="username"
                          formControlName="username">
                      </div>

                      <div
                        class="invalid-feedback display"
                        *ngIf="(!username.valid) && (username.dirty || username.touched)">
                        {{_(KEYS.LOGIN.ENTER_VALID_USERNAME)}}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="password">{{_(KEYS.LOGIN.PASSWORD)}}</label>

                      <div class="input-group mb-2 mb-sm-0">
                        <div class="input-group-addon">
                          <i class="fa fa-key"></i>
                        </div>
                        <input
                          type="password"
                          class="form-control form-control-lg rounded-2"
                          name="password"
                          id="password"
                          formControlName="password"
                          (keydown.enter)="(!loginForm.valid || loading) || onSubmit()">
                      </div>

                      <div
                        class="invalid-feedback display"
                        *ngIf="(!password.valid) && (password.dirty || password.touched)">
                        {{_(KEYS.LOGIN.ENTER_VALID_PASSWORD)}}
                      </div>
                    </div>

                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="remember"
                          name="remember"
                          formControlName="remember">
                        {{_(KEYS.LOGIN.STAY_LOGGED_IN)}}
                      </label>
                    </div>
                    <div *ngIf="!!errorMsg" class="remote-error">
                      <div class="alert alert-danger">
                        {{errorMsg}}
                      </div>
                    </div>
                    <br/>
                    <button
                      type="button"
                      class="btn btn-success btn-lg float-right"
                      [disabled]="!loginForm.valid || loading" (click)="onSubmit()">
                      {{_(KEYS.LOGIN.LOGIN)}}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
