import {Injectable, Optional} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {ErrorService} from '@ngmedax/error';
import {LayoutService} from '@ngmedax/layout';
import {ThemeService} from './theme.service';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';

declare const document: any;


// hack to inject decorator declarations. must occur before class declaration!
export interface ThemeGuard extends Translatable {}

@Injectable()
@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class ThemeGuard implements CanActivate {

  /**
   * Member to determine if theme was already loaded
   * @type {boolean}
   */
  private static themeLoaded = false;

  /**
   * Injects dependencies
   */
  public constructor(
    private themeService: ThemeService,
    private layoutService: LayoutService,
    private errorService: ErrorService,
    @Optional() private translationService: TranslationService,
  ) {
  }

  /**
   * Applies loaded theme to dom. Redirects to error page when theme service has failed to load css.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // if theme is not already loaded
    if (!ThemeGuard.themeLoaded) {

      if (this.themeService.hasFailedToLoadCss()) {
        this.showFailedMessage();
        ThemeGuard.themeLoaded = true;
        return false;
      }

      // try to get theme logo
      const logo = this.themeService.getLogo();

      // if logo found
      if (logo) {
        // set page logo by theme
        this.layoutService.setPageLogo(logo);
      }

      // try to get theme title
      const title = this.themeService.getTitle();

      // if title found
      if (title) {
        // set page title by theme
        this.layoutService.setPageTitle(title);
      }

      // try to get favicon
      const favicon = this.themeService.getFavicon();

      // if favicon found
      if (favicon) {
        // create new link el
        const faviconEl = document.createElement('link');

        // set props for favicon
        faviconEl.id = 'app-favicon';
        faviconEl.rel = 'shortcut icon';
        faviconEl.href = favicon;

        // try to get old favicon el
        const oldFaviconEl = document.getElementById('app-favicon');

        // if old favicon el found
        if (oldFaviconEl) {
          // remove it from dom
          document.head.removeChild(oldFaviconEl);
        }

        // add new favicon el
        document.head.appendChild(faviconEl);
      }

      // try to get css of theme
      const css = this.themeService.getCss();

      // if css found
      if (css) {
        // create style dom element
        const themeStyleTag = document.createElement('style');

        // add inline css to style dom element
        themeStyleTag.appendChild(document.createTextNode('\n' + css));

        // attach style dom element to the dom
        document.head.appendChild(themeStyleTag);
      }

      ThemeGuard.themeLoaded = true;
    }

    return true;
  }

  private showFailedMessage() {
    const cssFilePath = this.themeService.getCssFilePath();
    const uri = 'theme-not-found';

    const title = this._(KEYS.DEFAULT.THEMING_ERROR);
    const message = this._(KEYS.DEFAULT.ERROR_LOADING_CSS_THEME_FILE, {cssFilePath});

    this.errorService
      .addErrorMessage(uri, title, message)
      .navigateToError(uri);
  }
}
