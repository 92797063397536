import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Select2Component} from './select2.component';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    Select2Component
  ],
  exports: [
    Select2Component
  ],
  providers: []
})
export class Select2Module {
}
