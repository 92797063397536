import {Injectable} from '@angular/core';
import {TenantConfigService} from './tenant.config.service';

@Injectable()
export class TenantService {
  /**
   * The current tenant id
   */
  private tenantId: number;

  /**
   * Inits the tenant service
   *
   * @param {TenantConfigService} configService
   */
  constructor(private configService: TenantConfigService) {
  }

  /**
   * Returns the current tenant id
   *
   * @returns {number}
   */
  public getTenantId() {
    if (!this.tenantId) {
      // set tenant id by tenant config service
      this.tenantId = this.configService.getTenantId();
    }
    return this.tenantId;
  }
}
