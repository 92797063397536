import {ToastService} from './service/toast.service';
import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ToastrModule} from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({timeOut: 5000})
  ],
  declarations: [],
  exports: [],
  providers: [
    ToastService,
    {provide: 'TOAST_CONFIG', useValue: {alertHack: false, confirmHack: false}},
  ]
})
export class ToastModule {
  public static forRoot(config: {alertHack?: boolean, confirmHack?: boolean}): ModuleWithProviders<any> {

    return {
      ngModule: ToastModule,
      providers: [
        ToastService,
        {provide: 'TOAST_CONFIG', useValue: config},
      ]
    };
  }

  /**
   * Inject toast service. We need to init it once, to install the alert and prompt hacks
   * @param toastService
   */
  public constructor(
    toastService: ToastService
  ) {
  }
}
