import {Injectable} from '@angular/core';

/**
 * Service to run function/callback after a given timeout.
 */
@Injectable()
export class TimeoutService {
  /**
   * How many milliseconds to wait before we run the function
   *
   * @type {number}
   */
  private ms = 1000;

  /**
   * Timeout reference
   */
  private timeout: any;

  /**
   * Function to run
   */
  private fn: Function;

  /**
   * Timeout running?
   *
   * @type {boolean}
   */
  private running = false;

  /**
   * After how many milliseconds should we run the function?
   *
   * @param ms
   * @returns {TimeoutService}
   */
  public after(ms: number): TimeoutService {
    this.ms = ms;
    return this;
  }

  /**
   * Registers the function to run
   *
   * @param fn
   * @returns {TimeoutService}
   */
  public run(fn: Function): TimeoutService {
    this.fn = fn;
    this.reset();
    return this;
  }

  /**
   * Resets the timeout
   *
   * @returns {TimeoutService}
   */
  public reset(): TimeoutService {
    clearTimeout(this.timeout);
    this.running = true;

    this.timeout = setTimeout(() => {
      this.fn();
      this.running = false;
    }, this.ms);

    return this;
  }

  /**
   * Is there a timeout running?
   *
   * @returns {boolean}
   */
  public isRunning() {
    return this.timeout > 0;
  }
}
