import * as aes from 'aes-js';
import * as sha from 'sha.js';

/**
 * Class to generate authentication tokens by given username, password and shared secret
 */
export class AuthTokenService {
  /**
   * Returns a credential token by encrypting username and password by given key.
   * PasswordHash should be a base64 encoded sha256 hash of the password!
   *
   * @param {string} username
   * @param {string} passwordHash
   * @param {string} sharedSecret
   * @returns {string}
   */
  public encrypt(username: string, passwordHash: string, sharedSecret: string): string {
    const isBase64 = passwordHash.match(/^[a-z0-9+=\/]*$/i);
    const isCorrectLength = passwordHash.length === 44;

    if (!isCorrectLength || !isBase64) {
      throw new Error('param "passwordHash" must contain a base64 encoded sha256 hash of the password');
    }

    // get 128 bit key by shared secret
    const key = this.key(sharedSecret, 16);

    // counter for ctr
    const counter = this.getRandomInt(100, 9999);

    const credential = aes.utils.utf8.toBytes(
      JSON.stringify({u: username, p: passwordHash})
    );

    const cipher = new aes.ModeOfOperation.ctr(key, new aes.Counter(counter));
    const encryptedBytes = cipher.encrypt(credential);

    // encrypt and prefix with counter
    return counter.toString(16) + '.' + aes.utils.hex.fromBytes(encryptedBytes);
  }

  /**
   * Returns credential object by decrypting credential string via given key
   *
   * @param {string} credentialToken
   * @param {string} sharedSecret
   * @returns {{u: string, p: string}}
   */
  public decrypt(credentialToken: string, sharedSecret: string): {u: string, p: string} | {} {
    // extract counter from encrypted credential token
    const counter = parseInt(credentialToken.substring(0, credentialToken.indexOf('.')), 16);

    if (isNaN(counter)) {
      throw new Error('Malformed token given. Counter is invalid');
    }

    // get 128 bit key by shared secret
    const key = this.key(sharedSecret, 16);

    // remove counter
    credentialToken = credentialToken.replace(/.*?\./, '');

    const encryptedBytes = aes.utils.hex.toBytes(credentialToken);
    const cipher = new aes.ModeOfOperation.ctr(key, new aes.Counter(counter));
    const decrypted = aes.utils.utf8.fromBytes(cipher.decrypt(encryptedBytes));

    try {
      return JSON.parse(decrypted);
    } catch (error) {
      return {};
    }
  }

  /**
   * Checks if given string has the structure of a credential token
   *
   * @param {string} credentialToken
   * @returns {boolean}
   */
  public isToken(credentialToken: string): boolean {
    return credentialToken.match(/^.*?\..+$/) !== null;
  }

  /**
   * Pads string to given length of bytes.
   *
   * @param {string} str
   * @param {number} len
   * @param {string} chr
   * @returns {string}
   */
  private pad(str: string, len: number, chr = 'A'): string {
    for (let i = 0; i < len; i++) {
      str += chr;
    }
    return str.substr(0, len);
  }

  /**
   * Creates base64 encoded sha256 hash of key, pad's to given
   * length and converts to byte array.
   *
   * @param {string} key
   * @param {number} len
   * @param {string} chr
   * @return {array}
   */
  private key(key: string, len: number, chr = 'A'): number[] {
    key = this.pad(this.hash(key), len, chr);
    return aes.utils.utf8.toBytes(this.pad(key, len, chr));
  }

  /**
   * Creates and returns base64 encoded binary sha256 hash
   *
   * @param {string} key
   * @returns {string}
   */
  private hash(key: string): string {
    const u8Arr = sha('sha256').update(key).digest();
    const CHUNK_SIZE = 0x8000;
    let index = 0;
    const length = u8Arr.length;
    let result = '';
    let slice;

    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }

    return btoa(result);
  }

  /**
   * Returns a random number between min and max
   *
   * @param {number} min
   * @param {number} max
   * @returns {number}
   */
  private getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
