export class KeyPair {

  public constructor(
    private privateKey: Uint8Array,
    private publicKey: Uint8Array,
    private mnemonic: string = null
  ) {
  }

  public getPrivateKey(): Uint8Array {
    return this.privateKey;
  }

  public getPublicKey(): Uint8Array {
    return this.publicKey;
  }

  public getMnemonic(): string {
    return this.mnemonic;
  }
}