import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';
import {DATE_FORMAT_YMD_HM} from '@ngmedax/common-translation';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {UserService} from '../../services/user.service';
import {User} from '../../../../types';

import {TRANSLATION_GRID_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface UserGridComponent extends Translatable {}

/**
 * Component to render users in a grid
 */
@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.css']
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class UserGridComponent implements OnInit {
  /**
   * Is the search collapsed?
   * @type {boolean}
   */
  public isSearchCollapsed = true;

  /**
   * Is the grid collapsed?
   * @type {boolean}
   */
  public isGridCollapsed = false;

  /**
   * Default grid page number
   * @type {number}
   */
  public gridPageNumber = 1;

  /**
   * Default number of users to display on one page
   * @type {string}
   */
  public displayPerPage = 25;

  /**
   * List of users
   * @type {Array}
   */
  public users: User[] = [];

  /**
   * Filter to restrict users to show in grid
   * @type {{}}
   */
  public filter: User.Filter = {};

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  };

  /**
   * Loads users on component initialisation
   */
  public ngOnInit() {
    this.loadUsers();
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() public translationService: TranslationService,
    private layoutService: LayoutService,
    private userService: UserService,
    private ref: ChangeDetectorRef
  ) {
  }

  /**
   * Loads users
   */
  private loadUsers() {
    this.layoutService.showPreloader();

    this.userService.loadUsers()
      .then((users) => {
        this.users = users;
        this.layoutService.hidePreloader();
      })
      .catch(error => {
        alert(this._(KEYS.GRID.ERROR_LOADING_USERS));
        this.layoutService.hidePreloader();
        console.log(error);
      });
  }

  /**
   * Action for when a user is deleted
   *
   * @param {User} user
   */
  public async onDeleteUser(user: User) {
    const username = user.email;
    const sure = await confirm(this._(KEYS.GRID.QUESTION_DELETE_USER, {username}));

    if (!sure) {
      return;
    }

    // delete given user
    this.userService
      .deleteUser(user)
      .then(() => {
        // remove user from users array
        this.users.splice(this.users.indexOf(user), 1);
        alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_USER));
      })
      .catch(error => {
        console.error(error);
        alert(this._(KEYS.GRID.ERROR_DELETING_USER));
      });
  }

  /**
   * Action for when paging changed. Triggers the change detection
   */
  public onPagingChange() {
    // trigger change detection
    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  /**
   * Checks if the given position of a user should be displayed according to the currently selected page
   *
   * @param pos
   * @returns {boolean}
   */
  public isOnCurrentPage(pos) {
    const displayPerPage = this.displayPerPage;
    const maxPos = (displayPerPage * this.gridPageNumber) - 1;
    const minPos = (maxPos - displayPerPage) + 1;
    return pos >= minPos && pos <= maxPos;
  }

  /**
   * Returns users which are allowed by currently set filter
   *
   * @returns {Array}
   */
  public getUsers(): User[] {
    const filtered = [];

    for (const user of this.users) {
      if (this.isAllowedByFilter(user)) {
        filtered.push(user);
      }
    }

    return filtered;
  }

  /**
   * Checks if the given user is allowed by the currently set filter
   *
   * @param {User} user
   * @returns {any}
   */
  public isAllowedByFilter(user: User) {
    if (!this.filter) {
      return true;
    }

    const f = this.filter;
    const u = user;

    const emailMatches = f.email && u.email ?
      u.email.match(new RegExp(f.email, 'i')) :
      true;

    const firstNameMatches = f.firstName && u.data.firstName ?
      u.data.firstName.match(new RegExp(f.firstName, 'i')) :
      (!f.firstName);

    const lastNameMatches = f.lastName && u.data.lastName ?
      u.data.lastName.match(new RegExp(f.lastName, 'i')) :
      (!f.lastName);

    return emailMatches && firstNameMatches && lastNameMatches;
  }
}
