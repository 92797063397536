import {Injectable} from '@angular/core';
import {ValueService} from '@ngmedax/value';
import {CommonTranslationService} from '@ngmedax/common-translation';

@Injectable()
export class BaseTranslationService extends CommonTranslationService {
  /**
   * Injects dependencies
   *
   * @param {Interfaces.Value} value
   */
  public constructor(protected value: ValueService) {
    super(value);
  }
}
