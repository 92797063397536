import {Component, OnInit} from '@angular/core';
import {ErrorService} from './error.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TRANSLATION_ERROR_SCOPE} from '../constants';
import {KEYS} from '../translation-keys';
import {Translatable} from '@ngmedax/translation';


// hack to inject decorator declarations. must occur before class declaration!
export interface ErrorComponent extends Translatable {}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
@Translatable({scope: TRANSLATION_ERROR_SCOPE, keys: KEYS})
export class ErrorComponent implements OnInit {
  public title: string = '';
  public message: string = '';
  public exampleConfig: string = '';

  /**
   * Injects dependencies
   */
  public constructor(
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router) {}

  public ngOnInit() {
    // try to get message object
    const messageObj = this.errorService.getMessageObject(this.route.snapshot.params['uri']);

    // if we found no message object
    if (!messageObj) {
      location.href = '/';
    } else {
      // add message obj properties
      this.title = messageObj.title;
      this.message = messageObj.message;

      // if message object contains example config
      if (messageObj.exampleConfigObject) {
        // convert to prettified html
        this.exampleConfig = this.prettyJson(messageObj.exampleConfigObject);
      }
    }
  }

  /**
   * Converts object to prettified html json representation
   *
   * @param obj
   * @returns {string}
   */
  private prettyJson(obj: any): string {
    const styledObj = this.styleObject(obj);
    return JSON
      .stringify(styledObj, null, 2)
      .replace(/{/g, '<span class="object">{</span>')
      .replace(/}/g, '<span class="object">}</span>')
      .replace(/"<span/g, '<span')
      .replace(/<\/span>"/g, '</span>')
      .replace(/\\"/g, '"');
  }

  /**
   * Helper for "prettyJson". Replaces members with span.property elements and values with span.value elements
   *
   * @param obj
   * @returns {any}
   */
  private styleObject(obj: any): any {
    const intermediateObj = {};

    Object.keys(obj).forEach((property) => {
      const key = `<span class="text-primary">"${property}"</span>`;
      let value = obj[property];

      if (typeof value === 'object') {
        intermediateObj[key] = this.styleObject(value);
      } else {
        if (typeof value === 'string') {
          value = `"${value}"`;
        }
        intermediateObj[key] = `<span class="text-success">${value}</span>`;
      }
    });

    return intermediateObj;
  }
}
