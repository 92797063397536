import {Component, Input, OnInit, Optional} from '@angular/core';
import {TranslationEventService, TranslationService} from '@ngmedax/translation';

declare const $: any;

@Component({
  selector: 'app-locale-select',
  template:
    `<ng-container *ngIf="options && translationService">
      <app-select2
        cmpCssClasses="qa-select-locale"
        (selectedChange)="onSelectLocale($event)"
        [selected]="selectedLocale"
        [options]="options"
        [hideLabel]="true"
        [hideButton]="true"
      >
        <option value="{{locale}}" *ngFor="let locale of translationService.getLocales()">
          {{locale}}
        </option>
      </app-select2>
    </ng-container>`
})
export class LocaleSelectComponent implements OnInit  {
  @Input() public width: string = '100%';
  @Input() public selectedLocale: string = '';
  @Input() public dropdownCssClass: string = 'localebox-dropdown animated fadeIn faster';
  public options = null;

  public constructor(
    @Optional() public translationService: TranslationService,
    @Optional() private events: TranslationEventService
  ) {
  }

  public ngOnInit() {
    const t = this.translationService;
    if (!t) {
      return;
    }

    this.selectedLocale = t.getLocale();
    const iconRenderer = (item: {id: string, text: string}) => {
      const locale = item.id;
      const name = t.getLocaleName(locale);
      const country = t.getLocaleCountryCode(locale) ? t.getLocaleCountryCode(locale).toLowerCase() : '';

      if (this.translationService) {
        return $(`<span><span class="mr-1 flag-icon flag-icon-${country}"></span><span class="locale-name">${name}</span></span>`);
      }

      return locale;
    };

    this.options = {
      width: this.width,
      placeholder: '...',
      dropdownCssClass: this.dropdownCssClass,
      templateResult: iconRenderer,
      templateSelection: iconRenderer
    };

    this.events && this.events.onLocaleChanged().subscribe(() => {
      setTimeout(() => {
        if (this.selectedLocale !== this.translationService.getLocale()) {
          this.selectedLocale = this.translationService.getLocale();
        }
      }, 500);
    })
  }

  public onSelectLocale(locale: string) {
    this.selectedLocale = locale;
    this.translationService.setLocale(locale);
  }
}
