import {Injectable} from '@angular/core';
import * as sha from 'sha.js';
import * as bcrypt from 'bcryptjs';
import { Base64 as base64 } from 'js-base64';

import {User, PasswordPolicy} from '../../../types';
import {ConfigService} from '@ngmedax/config';


/**
 * Service to handle password related stuff
 */
@Injectable()
export class PasswordService {

  /**
   * Injects dependencies
   *
   * @param {ConfigService} configService
   */
  public constructor(private configService: ConfigService) {
  }

  /**
   * Checks if given password matches current one
   *
   * @param {User} user
   * @param {string} password
   * @returns {boolean}
   */
  public isCurrentPassword(user: User, password: string): boolean {
    const pwHash = this.getPasswordHash(password);
    const bCryptHash = base64.decode(user.salt) + base64.decode(user.hash);
    return bcrypt.compareSync(pwHash, bCryptHash);
  }

  /**
   * Returns configured password policy
   *
   * @returns {PasswordPolicy}
   */
  public getPasswordPolicy(): PasswordPolicy {
    const regexStrings = this.configService.get('policy.password.regex');
    const hint = this.configService.get('policy.password.hint') || '';
    const regex = [];

    if (Object.prototype.toString.call(regexStrings) === '[object Array]') {
      for (const regexString of regexStrings) {
        const matches = regexString.match(/^\/(.*?)\/(.*)$/);
        if (matches && matches[1]) {
          regex.push(new RegExp(matches[1], matches[2]));
        }
      }
    }

    return {regex, hint};
  }

  /**
   * Returns password hash by converting it to a binary
   * sha256 hash, which is then converted to base64
   *
   * @param password
   * @returns {string}
   */
  public getPasswordHash(password) {
    const u8Arr = sha('sha256').update(password).digest();
    const CHUNK_SIZE = 0x8000;
    let index = 0;
    const length = u8Arr.length;
    let result = '';
    let slice;
    while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return btoa(result);
  }
}
