import {Injectable} from '@angular/core';
import {ConfigService} from '@ngmedax/config';
import {AuthUriService} from './auth-uri.service';

@Injectable()
export class LoginConfigService {
  public constructor(
    private configService: ConfigService,
    private authUriService: AuthUriService
  ) {
  }

  /**
   * Returns url to ums login resource
   * @returns {string}
   */
  public getUmsLoginUrl(): string {
    return this.configService.get('apis.login.uri');
  }

  /**
   * Returns url to heartbeat resource
   * @returns {string}
   */
  public getUmsHeartbeatUrl(): string {
    return this.authUriService.buildUrl('apis.login.heartbeat.uri');
  }

  /**
   * Returns the tenant id for the currently active domain
   * @returns {Object}
   */
  public getTenantId(): number {
    // get tenant id by current domain
    return this.configService.get(`tenantId`);
  }

  /**
   * Returns the tenant id for the currently active domain
   * @returns {Object}
   */
  public getTenantName(): string {
    // get tenant name by current domain
    return this.configService.get(`name`);
  }
}
